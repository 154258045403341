<template>
    <div class="game-slider" v-if="1==2">
        <div class="game-slider__container">

            <swiper
                v-bind="swiper_options"
                class="game-slider__slider swiper">
                <swiper-slide v-for="i in 10">
                    <div class="game-slider__slide swiper-slide">
                        <div class="game-slider__slide-content">
                            <div class="game-slider__label">
                                <span>NEW</span>
                                <svg width="59" height="29" viewBox="0 0 59 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H59V25.8929L9.83333 29L0 0Z" fill="#FFFF00" />
                                </svg>
                            </div>
                            <div class="game-slider__provider">
                                <picture>
                                    <source srcset="/assets/img/game-cards/provider.webp" type="image/webp" />
                                    <img src="/assets/img/game-cards/provider.png" width="100" height="24" alt="" />
                                </picture>
                            </div>
                            <div class="game-slider__main game-card">
                                <div class="game-card__img">
                                    <picture>
                                        <source srcset="/assets/img/game-cards/01.webp" type="image/webp" />
                                        <img src="/assets/img/game-cards/01.png" width="183" height="120" alt="" />
                                    </picture>
                                    <div class="game-card__hover game-slider-hover">
                                        <div title="Sizzling moon" class="game-slider-hover__title">Sizzling moon</div>
                                        <button type="button" class="game-slider-hover__play" data-popup="_sign-in">
                                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -7.31543e-07 14.7358 -6.64245e-07 13.1962L-2.09983e-07 2.80385C-1.42685e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </button>
                                        <a href="" class="game-slider-hover__link">демо</a>
                                    </div>
                                </div>
                            </div>
                            <div class="game-slider__bet">
                                Ставка:
                                <span>0.20 USD - 10 000 USD</span>
                            </div>
                        </div>
                    </div>
                </swiper-slide>

                <template>
                    <button type="button" class="swiper-button-prev">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.10608 3.81213L2.95825 7.71428L7.10608 11.6164" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14.5749 7.71423H3.07439" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 0H0V32.1429L34.1667 36L41 0Z" fill="#FFFF00" />
                        </svg>
                    </button>
                    <button type="button" class="swiper-button-next">
                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.89392 3.81213L14.0418 7.71428L9.89392 11.6164" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.42517 7.71423H13.9257" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H41V32.1429L6.83333 36L0 0Z" fill="#FFFF00" />
                        </svg>
                    </button>
                </template>
            </swiper>

        </div>
    </div>
</template>


<script>
    import { Navigation, Pagination, Autoplay } from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

    SwiperCore.use([Navigation, Pagination, Autoplay])

    export default {
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                swiper_options: {
                    autoplay: {
                        delay: 2700, 
                        disableOnInteraction: false
                    },
                    observer: true,
                    observeParents: true,
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                    autoHeight: true,
                    speed: 800,
                    navigation: {
                        nextEl: '.swiper-button-next', 
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        499.98: {
                            slidesPerView: 2
                        }, 
                        767.98: {
                            slidesPerView: 3
                        }, 
                        1023.98: {
                            slidesPerView: 4
                        }, 
                        1199.98: {
                            slidesPerView: 5
                        }
                    }
                }
            }
        },
        beforeMount() {
            
        }
    }
</script>