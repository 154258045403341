<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="crash"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container content--mb">
                    <h1 class="content__title title title--center">{{ $store.state.translation.crash.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.crash.text1 }}
                            </p>
                            <h3 class="content__title mini-title">{{ $store.state.translation.crash.text2 }}</h3>
                            <ul class="list">
                                <li>{{ $store.state.translation.crash.text3 }}</li>
                                <li>{{ $store.state.translation.crash.text4 }}</li>
                                <li>{{ $store.state.translation.crash.text5 }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.crash.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.crash.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.crash.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.crash.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
