import axios from 'axios'
import store from '../../events/Store'
import config from '../../events/Config'

export default (() =>
    axios.create({
        withCredentials: true,
        baseURL: config.ApplicationAPI,
        headers: {
            common: {
                Authorization: localStorage.getItem('token') ? ('Bearer ' + localStorage.getItem('token')) : null,
                Lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : null
            }
        }
    })
)()