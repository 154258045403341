<template>
  <div id="app">
    <auth v-if="!$store.state.user.auth"/>
    <router-view/>
  </div>
</template>

<style>
  @import './assets/css/style.min.css';
</style>

<script>
export default {
  name: 'App'
}
</script>
