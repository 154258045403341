<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="live_games"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.live_games.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.live_games.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#types" data-goto-header>{{ $store.state.translation.live_games.types }}</button></li>
                                    <li><button type="button" data-goto="#poker" data-goto-header>{{ $store.state.translation.live_games.poker }}</button></li>
                                    <li><button type="button" data-goto="#blackjack" data-goto-header>{{ $store.state.translation.live_games.blackjack }}</button></li>
                                    <li><button type="button" data-goto="#baccarat" data-goto-header>{{ $store.state.translation.live_games.baccarat }}</button></li>
                                    <li><button type="button" data-goto="#roulette" data-goto-header>{{ $store.state.translation.live_games.roulette }}</button></li>
                                    <li><button type="button" data-goto="#sic-bo" data-goto-header>{{ $store.state.translation.live_games.sic_bo }}</button></li>
                                    <li><button type="button" data-goto="#monopoly" data-goto-header>{{ $store.state.translation.live_games.monopoly }}</button></li>
                                    <li><button type="button" data-goto="#dealer" data-goto-header>{{ $store.state.translation.live_games.dealer }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.live_games.game }}</button></li>
                                    <li><button type="button" data-goto="#questions" data-goto-header>{{ $store.state.translation.live_games.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.live_games.text1 }}
                            </p>
                            <h3 class="content__title mini-title">{{ $store.state.translation.live_games.text2 }}</h3>
                            <ul class="list">
                                <li>{{ $store.state.translation.live_games.text3 }}</li>
                                <li>{{ $store.state.translation.live_games.text4 }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="content__container">
                    <ul class="list">
                        <li>{{ $store.state.translation.live_games.text5 }}</li>
                        <li>{{ $store.state.translation.live_games.text6 }}</li>
                        <li>{{ $store.state.translation.live_games.text7 }}</li>
                    </ul>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <section id="types" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text8 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text9 }}
                            </p>
                        </div>
                    </section>
                    <section id="poker" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text10 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text11 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.live_games.text12 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.live_games.text13 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.live_games.text14 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="blackjack" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text15 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text16 }}
                            </p>
                            <p>
                                {{ $store.state.translation.live_games.text17 }}
                            </p>
                        </div>
                    </section>
                    <section id="baccarat" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text18 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text19 }}
                            </p>
                            <p>
                                {{ $store.state.translation.live_games.text20 }}
                            </p>
                        </div>
                    </section>
                    <section id="roulette" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text21 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text22 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.live_games.text23 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text24 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text25 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text26 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text27 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text28 }}
                                </li>
                                <li>{{ $store.state.translation.live_games.text29 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="sic-bo" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text30 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text31 }}
                            </p>
                        </div>
                    </section>
                    <section id="monopoly" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text32 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text33 }}
                            </p>
                        </div>
                    </section>
                    <section id="dealer" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text34 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text35 }}
                            </p>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text36 }}</h2>
                            <p>
                                {{ $store.state.translation.live_games.text37 }}
                            </p>
                            <p>
                                {{ $store.state.translation.live_games.text38 }}
                            </p>
                            <p>
                                {{ $store.state.translation.live_games.text39 }}
                            </p>
                        </div>
                    </section>

                    <section id="questions" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.live_games.text40 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.live_games.text41 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.live_games.text42 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.live_games.text43 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.live_games.text44 }}</p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.live_games.text45 }}</h3>
                                    <p>{{ $store.state.translation.live_games.text46 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.live_games.text47 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.live_games.text48 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.live_games.text49 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.live_games.text50 }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.live_games.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.live_games.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.live_games.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.live_games.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
