<template>
    <div class="wrapper">
        
        <header-main></header-main>

        <main class="page">
            <h1 class="graph-title">
                <span>
                    <svg class="_animation-object _animation-object-dashes" width="168" height="43" viewBox="0 0 168 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M153.094 17.6816L138.864 3.52086L124.625 17.6816L110.395 3.52086L96.1652 17.6816L83.6144 5.19142L71.0549 17.6816L56.8249 3.52086L42.5862 17.6816L28.3562 3.52086L14.1262 17.6816L0 3.62473L1.57534 2.04939L14.1262 14.5396L28.3562 0.378829L42.5862 14.5396L56.8249 0.378829L71.0549 14.5396L83.6144 2.04073L96.1652 14.5396L110.395 0.378829L124.625 14.5396L138.864 0.378829L153.094 14.5396L165.653 2.04939L167.22 3.62473L153.094 17.6816Z"
                            fill="#E9417D"
                        ></path>
                        <path
                            d="M153.094 43L138.864 28.8392L124.625 43L110.395 28.8392L96.1652 43L84.8781 31.7649L84.8694 28.6142L96.1652 39.858L110.395 25.6972L124.625 39.858L138.864 25.6972L153.094 39.858L165.653 27.3677L167.22 28.9517L153.094 43Z"
                            fill="#E9417D"
                        ></path>
                    </svg>
                    <span>{{ $store.state.translation.info.integrity.title }}</span>
                    <svg class="_animation-object _animation-object-cross" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.4 11.36L8.95 6.90999L13.4 2.45999C13.96 1.89999 13.96 0.98 13.4 0.42C12.84 -0.14 11.92 -0.14 11.36 0.42L6.90999 4.87L2.45999 0.42C1.89999 -0.14 0.98 -0.14 0.42 0.42C-0.14 0.98 -0.14 1.89999 0.42 2.45999L4.87 6.90999L0.42 11.36C-0.14 11.92 -0.14 12.84 0.42 13.4C0.98 13.96 1.89999 13.96 2.45999 13.4L6.90999 8.95L11.36 13.4C11.92 13.96 12.84 13.96 13.4 13.4C13.97 12.84 13.97 11.93 13.4 11.36Z"
                            fill="#FFC263"
                        ></path>
                    </svg>
                    <svg class="_animation-object _animation-object-cross" width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.18414 19.9265L19.9348 5.17578C17.3161 6.67223 14.8843 8.46262 12.6842 10.4935L10.5019 12.6758C8.47098 14.876 6.68059 17.3077 5.18414 19.9265Z" fill="#E9417D"></path>
                        <path d="M0.783875 31.6485L31.657 0.775391C30.8019 0.944632 29.9646 1.1495 29.1273 1.37219L1.38066 29.1188C1.15797 29.9561 0.953116 30.7934 0.783875 31.6485Z" fill="#E9417D"></path>
                        <path d="M0 39.4955C0 39.5757 0 39.6648 0 39.7449L39.7538 0C39.6737 0 39.5846 0 39.5044 0C38.9255 0 38.3554 0.0178148 37.7942 0.0356297L0.0445263 37.7853C0.017804 38.3554 0 38.9255 0 39.4955Z" fill="#E9417D"></path>
                        <path d="M0.614646 46.4613L46.4612 0.614761C45.9089 0.516779 45.3478 0.427705 44.7955 0.356445L0.356323 44.7956C0.427583 45.3479 0.516665 45.909 0.614646 46.4613Z" fill="#E9417D"></path>
                        <path d="M2.11105 52.2782L52.2777 2.11162C51.7967 1.94238 51.3068 1.79096 50.8169 1.64844L1.64787 50.8174C1.7993 51.3073 1.95072 51.7972 2.11105 52.2782Z" fill="#E9417D"></path>
                        <path d="M4.29341 57.4176L57.4262 4.28477C56.9986 4.07099 56.5622 3.85721 56.1257 3.65234L3.66096 56.1171C3.86584 56.5536 4.07073 56.99 4.29341 57.4176Z" fill="#E9417D"></path>
                        <path d="M7.0369 61.9956L62.0046 7.02788C61.6216 6.76065 61.2386 6.50234 60.8467 6.25293L6.26196 60.8376C6.51137 61.2296 6.76968 61.6126 7.0369 61.9956Z" fill="#E9417D"></path>
                        <path d="M10.2792 66.0757L66.0753 10.2795C65.7368 9.97668 65.3984 9.67383 65.051 9.37988L9.37956 65.0513C9.67351 65.3898 9.97637 65.7372 10.2792 66.0757Z" fill="#E9417D"></path>
                        <path d="M14.0114 69.6645L69.6739 14.002C69.38 13.6546 69.0771 13.3072 68.7743 12.9688L12.9781 68.7649C13.3166 69.0677 13.6551 69.3706 14.0114 69.6645Z" fill="#E9417D"></path>
                        <path d="M18.2157 72.7737L72.7826 18.2068C72.5332 17.8149 72.2749 17.4318 72.0077 17.0488L17.0578 72.0077C17.4408 72.266 17.8238 72.5243 18.2157 72.7737Z" fill="#E9417D"></path>
                        <path d="M22.9456 75.3662L75.3658 22.946C75.1609 22.5095 74.956 22.0731 74.7333 21.6455L21.6362 74.7427C22.0726 74.9565 22.5091 75.1613 22.9456 75.3662Z" fill="#E9417D"></path>
                        <path d="M28.2632 77.3703L77.3788 28.2548C77.2363 27.7649 77.076 27.2749 76.9156 26.7939L26.8024 76.9071C27.2834 77.0764 27.7733 77.2278 28.2632 77.3703Z" fill="#E9417D"></path>
                        <path d="M34.3025 78.6527L78.6615 34.2937C78.5902 33.7326 78.5012 33.1714 78.4032 32.6191L32.6279 78.3944C33.1802 78.4924 33.7413 78.5815 34.3025 78.6527Z" fill="#E9417D"></path>
                        <path
                            d="M39.5045 78.9995C40.1191 78.9995 40.7248 78.9817 41.3216 78.955L78.9554 41.3211C78.9822 40.7154 79 40.1097 79 39.504C79 39.4505 79 39.406 79 39.3525L39.353 78.9995C39.3976 78.9906 39.451 78.9995 39.5045 78.9995Z"
                            fill="#E9417D"
                        ></path>
                        <path d="M50.0152 77.5749L77.5837 50.0064C77.8153 49.1691 78.0202 48.3229 78.1983 47.4678L47.4766 78.1895C48.3317 78.0113 49.1779 77.8065 50.0152 77.5749Z" fill="#E9417D"></path>
                        <path d="M73.6733 59.3057L59.3056 73.6733C65.2558 70.2173 70.2172 65.2558 73.6733 59.3057Z" fill="#E9417D"></path>
                    </svg>
                    <svg class="_animation-object _animation-object-rhombus" width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.76 10.76L0 0V21.52L10.76 10.76Z" fill="#DA5077"></path>
                        <path d="M27.1 10.76L16.33 0V21.52L27.1 10.76Z" fill="#DA5077"></path>
                    </svg>
                </span>
            </h1>
            <section class="content content--mb">
                <div class="content__container">
                    <h2 class="content__title title">{{ $store.state.translation.info.integrity.text1 }}</h2>
                    <p>
                        {{ $store.state.translation.info.integrity.text2 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.integrity.text3 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.integrity.text4 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.integrity.text5 }}
                    </p>
                    <p>
                        {{ $store.state.translation.info.integrity.text6 }}
                    </p>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.info.integrity.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.info.integrity.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.info.integrity.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.info.integrity.meta.description
                    }
                ]
            }
        }
    }
</script>
