<template>
    <div class="wrapper">
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb content--mb-very-big">
                <div class="content__container">

                    <account-personal-info></account-personal-info>
                    
                    <table class="content__table table-pc table-pc--tree">
                        <caption>
                            {{ $store.state.translation.account.messages.title }}
                        </caption>
                        <thead>
                            <tr>
                                <th>{{ $store.state.translation.account.messages.message }}</th>
                                <th>{{ $store.state.translation.account.messages.subject }}</th>
                                <th>{{ $store.state.translation.account.messages.date }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in messages" :class="{'message__active': row.status == 0}">
                                <td>{{ row.title }}</td>
                                <td>{{ row.message }}</td>
                                <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>

                            </tr>
                        </tbody>
                    </table>

                    <div class="show-more__container">
                        <div class="show-more__row show-more__row--center">
                            <button type="button" class="show-more__more" v-if="!nomore" @click="getMessages" :class="{'_disabled': preloader}">
                                <span>{{ $store.state.translation.default.show_more }}</span>
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.account.messages.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.account.messages.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.account.messages.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.account.messages.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                messages: [],
                data: {
                    limit: 10,
                    offset: 0
                },
                nomore: true,
                preloader: false
            }
        },
        methods: {
            getMessages () {
                this.preloader = true 
                this.$axios.post('/messages/items', JSON.stringify(this.data))
                .then((response) => {
                    this.setLoaded(true)
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.messages = this.messages.concat(response.messages)
                            this.data.offset ++
                            if(response.messages.length < this.data.limit) {
                                this.nomore = true
                            } else {
                                this.nomore = false
                            }

                            this.preloader = false

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })     
            }
        },
        beforeMount() {
            this.setLoaded(false)
            this.getMessages()
        }
    }
</script>


<style lang="css" scoped>
    /*::v-deep .content {
        min-height: 30rem;
    }*/
</style>
