<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="jackpots"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.jackpots.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.jackpots.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#jackpot-games" data-goto-header>{{ $store.state.translation.jackpots.jackpot_games }}</button></li>
                                    <li><button type="button" data-goto="#progressive" data-goto-header>{{ $store.state.translation.jackpots.progressive }}</button></li>
                                    <li><button type="button" data-goto="#fixed" data-goto-header>{{ $store.state.translation.jackpots.fixed }}</button></li>
                                    <li><button type="button" data-goto="#3-or-more" data-goto-header>{{ $store.state.translation.jackpots.threeormore }}</button></li>
                                    <li><button type="button" data-goto="#free" data-goto-header>{{ $store.state.translation.jackpots.free }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.jackpots.game }}</button></li>
                                    <li><button type="button" data-goto="#questions" data-goto-header>{{ $store.state.translation.jackpots.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>{{ $store.state.translation.jackpots.text1 }}</p>
                            <h3 class="content__title mini-title">{{ $store.state.translation.jackpots.text2 }}</h3>
                            <p>{{ $store.state.translation.jackpots.text3 }}</p>
                        </div>
                    </div>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">

                    <div id="jackpot-games" class="content">
                        <div class="content__container">
                            <table class="content__table table table--two">
                                <caption>{{ $store.state.translation.jackpots.text4 }}</caption>
                                <thead>
                                    <tr>
                                        <th>{{ $store.state.translation.jackpots.text5 }}</th>
                                        <th>{{ $store.state.translation.jackpots.text6 }}</th>
                                        <th>{{ $store.state.translation.jackpots.text7 }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/ainsworthgametechnology.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$30 000</td>
                                        <td>
                                            South of the Border, Roaming Reels RR, Arabian Fire Loaded with Loot
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/belatra.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$50 000</td>
                                        <td>
                                            Bank Robbers
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$62 842</td>
                                        <td>
                                            Greedy Goblins
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/blueprint.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$10 000</td>
                                        <td>
                                            Rhino Rampage Lightning Spins
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/boominggames.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$500 000</td>
                                        <td>
                                            Boom Shakalaka
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/booongo.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$120 000</td>
                                        <td>
                                            Great Panda: Hold and Win
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$7 205 120</td>
                                        <td>
                                            Mega Moolah, Mega Moolah Isis, Absolootly Mad: Mega Moolah
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$40 972</td>
                                        <td>
                                            Divine Fortune
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$27 096</td>
                                        <td>
                                            Fruit Bonanza
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/playson.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$100 000 </td>
                                        <td>
                                            Sunny Fruits: Hold and Win
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/pragmaticplay.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$125 000 </td>
                                        <td>
                                            Pyramid King
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/quickspin.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$62 925</td>
                                        <td>
                                            Dragon Chase
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/reelplay.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Х2000</td>
                                        <td>
                                            Hyperova Megaways
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/relaxgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$100 000</td>
                                        <td>
                                            King Of Kings
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/spinomenal.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$1 000 000</td>
                                        <td>
                                            Arctic Adventures
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/tomhorn.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$17 760</td>
                                        <td>
                                            Dragon Riches
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/wazdan.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$95 000 </td>
                                        <td>
                                            Black Horse Deluxe
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/yggdrasilgaming.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$52 958</td>
                                        <td>
                                            Jackpot Raiders
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/gameart.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$250 000</td>
                                        <td>
                                            Piggy Holmes
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/hacksawgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$200 000</td>
                                        <td>
                                            Dream Car Speed
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/bfgames.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$44 381</td>
                                        <td>
                                            Во всех играх
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/amatic.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Прогрессивный</td>
                                        <td>
                                            Lucky Egypt
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/justforthewin.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Х10 000</td>
                                        <td>
                                            Immortal Glory
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/redrake.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$18 000</td>
                                        <td>
                                            Super 12 Star
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/revolvergaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>5 прогрессивных</td>
                                        <td>
                                            Neon Blaze
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/swintt.svg" width="40" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>$2 000 000</td>
                                        <td>
                                            Power Fruits, Fruit Fever
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>{{ $store.state.translation.jackpots.text8 }}</p>
                        </div>
                    </div>

                    <section id="progressive" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text9 }}</h2>
                            <p>
                                {{ $store.state.translation.jackpots.text10 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.jackpots.text11 }}</li>
                                <li>
                                    {{ $store.state.translation.jackpots.text12 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.jackpots.text13 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.jackpots.text14 }}
                            </p>
                        </div>
                    </section>

                    <section id="fixed" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text15 }}</h2>
                            <p>
                                {{ $store.state.translation.jackpots.text16 }}
                            </p>
                            <ul class="list">
                                <li><a href="" target="_blank" rel="noopener noreferrer">Neon Rush</a> - Yggdrasil Gaming;</li>
                                <li><a href="" target="_blank" rel="noopener noreferrer">Gold Money Frog</a> - NetEnt;</li>
                                <li><a href="" target="_blank" rel="noopener noreferrer">Lara Croft Temples and Tombs</a> - Microgaming;</li>
                                <li><a href="" target="_blank" rel="noopener noreferrer">Diamond Strike</a> - Pragmatic Play;</li>
                                <li><a href="" target="_blank" rel="noopener noreferrer">El Dorado Infinity Reels</a> - Reel Play.</li>
                            </ul>
                            <p>
                                {{ $store.state.translation.jackpots.text17 }}
                            </p>
                        </div>
                    </section>

                    <section id="3-or-more" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text18 }}</h2>
                            <p>
                                {{ $store.state.translation.jackpots.text19 }}
                            </p>
                            <table class="table table--tree">
                                <caption>{{ $store.state.translation.jackpots.text20 }}</caption>
                                <thead>
                                    <tr>
                                        <th>{{ $store.state.translation.jackpots.text21 }}</th>
                                        <th>{{ $store.state.translation.jackpots.text22 }}</th>
                                        <th>{{ $store.state.translation.jackpots.text23 }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a target="_blank">
                                                Neon Rush Spiitz
                                            </a>
                                        </td>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/yggdrasilgaming.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Rush, Neon, Hyper, Mega, Superior, Ultra</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target="_blank">
                                                Mustang Gold
                                            </a>
                                        </td>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/pragmaticplay.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Grand, Major, Minor, Mini</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target="_blank">
                                                Aztec Sun Hold and Win
                                            </a>
                                        </td>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/booongo.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Grand, Major, Mini</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target="_blank">
                                                Krakatoa Lucky Break
                                            </a>
                                        </td>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/ainsworthgametechnology.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Grand, Major, Maxi, Minor, Mini</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a target="_blank">
                                                Wild Catch
                                            </a>
                                        </td>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>Wild, Silver, Bronze</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>

                    <section id="free" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text24 }}</h2>
                            <p>{{ $store.state.translation.jackpots.text25 }}</p>
                            <p>{{ $store.state.translation.jackpots.text26 }}</p>
                        </div>
                    </section>

                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text27 }}</h2>
                            <p>
                                {{ $store.state.translation.jackpots.text28 }}
                            </p>
                            <p>
                                {{ $store.state.translation.jackpots.text29 }}
                            </p>
                        </div>
                    </section>

                    <section id="questions" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.jackpots.text30 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.jackpots.text31 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.jackpots.text32 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.jackpots.text33 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.jackpots.text34 }}</p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.jackpots.text35 }}</h3>
                                    <p>{{ $store.state.translation.jackpots.text36 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.jackpots.text37 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.jackpots.text38 }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.jackpots.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.jackpots.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.jackpots.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.jackpots.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
