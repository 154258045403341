<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="lotteries"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.lotteries.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.lotteries.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#types" data-goto-header>{{ $store.state.translation.lotteries.types }}</button></li>
                                    <li><button type="button" data-goto="#scratch" data-goto-header>{{ $store.state.translation.lotteries.scratch }}</button></li>
                                    <li><button type="button" data-goto="#bingo" data-goto-header>{{ $store.state.translation.lotteries.bingo }}</button></li>
                                    <li><button type="button" data-goto="#keno" data-goto-header>{{ $store.state.translation.lotteries.keno }}</button></li>
                                    <li><button type="button" data-goto="#lotteries" data-goto-header>{{ $store.state.translation.lotteries.lotteries }}</button></li>
                                    <li><button type="button" data-goto="#free" data-goto-header>{{ $store.state.translation.lotteries.free }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.lotteries.game }}</button></li>
                                    <li><button type="button" data-goto="#questions" data-goto-header>{{ $store.state.translation.lotteries.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.lotteries.text1 }}
                            </p>
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text2 }}</h2>
                            <p>{{ $store.state.translation.lotteries.text3 }}</p>
                            <ul class="list">
                                <li>{{ $store.state.translation.lotteries.text4 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text5 }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="content__container">
                    <ul class="list">
                        <li>{{ $store.state.translation.lotteries.text6 }}
                        </li>
                        <li>{{ $store.state.translation.lotteries.text7 }}
                        </li>
                        <li>{{ $store.state.translation.lotteries.text8 }}
                        </li>
                        <li>{{ $store.state.translation.lotteries.text9 }}
                        </li>
                        <li>{{ $store.state.translation.lotteries.text10 }}
                        </li>
                    </ul>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <section id="types" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text11 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text12 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.lotteries.text13 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text14 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text15 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text16 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.lotteries.text17 }}
                            </p>
                            <div class="providers providers--tree">
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/1x2gaming.png" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/belatra.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/hacksawgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/spinomenal.png" width="100" height="27" alt="">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="scratch" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text18 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text19 }}
                            </p>
                            <p>
                                {{ $store.state.translation.lotteries.text20 }}
                            </p>
                        </div>
                    </section>
                    <section id="bingo" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text21 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text22 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.lotteries.text23 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text24 }}
                                </li>
                                <li>{{ $store.state.translation.lotteries.text25 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.lotteries.text26 }}
                            </p>
                        </div>
                    </section>
                    <section id="keno" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text27 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text28 }}
                            </p>
                        </div>
                    </section>
                    <section id="lotteries" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text29 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text30 }}
                            </p>
                            <ul class="list">
                                <li>
                                    {{ $store.state.translation.lotteries.text31 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.lotteries.text32 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.lotteries.text33 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.lotteries.text34 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="free" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text35 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text36 }}
                            </p>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text37 }}</h2>
                            <p>
                                {{ $store.state.translation.lotteries.text38 }}
                            </p>
                        </div>
                    </section>
                    <section id="questions" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.lotteries.text39 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.lotteries.text40 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.lotteries.text41 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.lotteries.text42 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.lotteries.text43 }}</p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.lotteries.text44 }}</h3>
                                    <p>{{ $store.state.translation.lotteries.text45 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.lotteries.text46 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.lotteries.text47 }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.lotteries.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.lotteries.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.lotteries.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.lotteries.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
