<template>
	<div class="game-cards">

        <div class="games-preloader" v-if="!$store.state.loaded_games">
            <div class="preloader__row">
                <div class="preloader__item"></div>
                <div class="preloader__item"></div>
            </div>
        </div>

        <div class="game-cards__container" v-if="$store.state.loaded_games">
            <div class="promo promo--first" v-if="$props.games.length >= 13">
                <div class="promo__wrap">
                    <picture>
                        <img src="../../assets/img/promo/start_home.png" width="368" height="260" alt="" />
                    </picture>
                    <div class="promo-link">
                        <router-link :to="{name: 'PromoStarting'}">
                            {{ $store.state.translation.common.home_game_cards.details }}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="promo promo--last promo--form" v-if="$props.games.length >= 33">
                <picture>
                    <img src="../../assets/img/promo/status_home.png" width="368" height="260" alt="" />
                </picture>
                <div class="promo-link">
                    <router-link :to="{name: 'PromoStatus'}">
                        {{ $store.state.translation.common.home_game_cards.details }}
                    </router-link>
                </div>
            </div>
            <div class="promo promo--two promo--form" v-if="$props.games.length >= 33">
                <picture>
                    <img src="../../assets/img/promo/cashback_home.png" width="368" height="260" alt="" />
                </picture>
                <div class="promo-link">
                    <router-link :to="{name: 'PromoCashback'}">
                        {{ $store.state.translation.common.home_game_cards.details }}
                    </router-link>
                </div>
            </div>


            <div class="game-cards__item game-card" v-for="game in $props.games">
                <div class="game-slider__label new" v-if="game.is_new">
                    <span>NEW</span>
                    <svg width="59" height="29" viewBox="0 0 59 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H59V25.8929L9.83333 29L0 0Z"></path>
                    </svg>
                </div>
                <div class="game-slider__label hit" v-if="game.is_hit && !game.is_new">
                    <span>HIT</span>
                    <svg width="59" height="29" viewBox="0 0 59 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H59V25.8929L9.83333 29L0 0Z"></path>
                    </svg>
                </div>

                <div v-if="'auth' in $store.state.user && $store.state.user.auth">
                    <div
                        v-if="$store.state.user.data.games_favourite.includes(game.id)"
                        :class="{
                            'favourite': $store.state.user.data.games_favourite.includes(game.id),
                            'not__only': game.is_new || game.is_hit
                        }"
                        @click="favourite(game.id)"
                        class="game-slider__label">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="20px" width="20px" version="1.1" viewBox="-2.88 -2.88 53.70 53.70" xml:space="preserve" fill="#ff0" stroke="#ff0" stroke-width="4.794">
                            <g>
                                <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z" style="fill: #ff0;"></path>
                            </g>
                        </svg>
                        <svg viewBox="145.012 146.324 34.675 29" width="34.675" height="29">
                          <path d="M 145.012 146.324 L 179.687 146.324 L 179.687 172.217 L 150.791 175.324 L 145.012 146.324 Z" style=""></path>
                        </svg>
                    </div>
                </div>

                <div class="game-card__main">
                    <div class="game-card__img">
                       <img :src="game.image" width="175" height="120" :alt="game.name">
                    </div>
                    <div class="game-card__hover game-slider-hover">
                        <div v-if="'auth' in $store.state.user && $store.state.user.auth">
                            <div
                                @click="favourite(game.id)"
                                class="game-fauvorite">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="20px" width="20px" version="1.1" viewBox="-2.88 -2.88 53.70 53.70" xml:space="preserve" fill="#e9417d" stroke="#e9417d" stroke-width="4.794">
                                    <g>
                                        <path :class="{'not_selected': !$store.state.user.data.games_favourite.includes(game.id)}" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>

                       <template v-if="'auth' in $store.state.user && $store.state.user.auth">
                            <router-link :to="{name: 'Game', params: {mode: 'real', slug: game.slug}}" class="game-slider-hover__play">
                                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -7.31543e-07 14.7358 -6.64245e-07 13.1962L-2.09983e-07 2.80385C-1.42685e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z"
                                        fill="white"/>
                                </svg>
                            </router-link>
                        </template>
                        <template v-else>
                            <button type="button" class="game-slider-hover__play" @click="openPopup('sign-in')">
                                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -7.31543e-07 14.7358 -6.64245e-07 13.1962L-2.09983e-07 2.80385C-1.42685e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z"
                                        fill="white"/>
                                </svg>
                            </button>
                        </template>
                        <router-link :to="{name: 'Game', params: {mode: 'demo', slug: game.slug}}" class="game-slider-hover__link">{{ $store.state.translation.common.home_game_cards.demo }}</router-link>
                    </div>
                </div>
                <div class="game-card__footer">
                    <div class="game-card__title">{{game.name}}</div> 
                    <div class="game-card__title game-card__title--hover">{{game.name}}</div> 
                </div>
            </div>
        </div>

        <div class="show-more show-more--mb show-more--mt">
            <div class="show-more__container">
                <div class="show-more__row show-more__row--center">
                    <button 
                        v-if="!$store.state.games_search_nomore" 
                        @click="$emit('searchGames')" 
                        :class="{'_disabled': !$store.state.loaded_games}"
                        type="button" 
                        class="show-more__more">
                        <span>{{ $store.state.translation.default.show_more }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props: {
    		games: {
    			type: Array
    		}
    	},
        methods: {
            favourite (game) {
                this.$axios.post('/games/favourite', JSON.stringify({
                    game: game
                }))
                .then((response) => {
                      response = response.data
                      switch (response.status) {
                          case 'error':
                              break
                          case 'success':

                              this.setUser(response.user)

                              break
                          default:
                          //Error
                      }
                })
            }
        }
    }
</script>