<template>
    <div class="wrapper">
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb">
                <div class="content__container">
                    
                    <cashier-personal-info></cashier-personal-info>
                    
                </div>
            </section>
            <section class="content content--mb content--mb-very-big" v-if="paysystem">
                <div class="content__container">
                    <div class="cash cash--mw">
                        <div class="cash__column">
                            <div class="cash__header cash__header--col">
                                <div class="cash__row">
                                    <router-link class="cash__prev" :to="{name: 'CashierWithdraw'}">
                                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9388 12.3545L7.29297 25.0003L19.9388 37.6462" stroke="white" stroke-width="3.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M42.707 25H7.64453" stroke="white" stroke-width="3.125" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                    </router-link>
                                    <picture>
                                        <img :src="`${$config.ApplicationURL}/paysys_img/${paysystem.image}`" width="121"/>
                                    </picture>
                                    <h2 class="cash__title">{{ paysystem.name }}</h2>
                                </div>
                                <div class="cash__min">
                                    {{ $store.state.translation.cashier.withdraw_type.payment_time }}
                                </div>
                            </div>
                            <div class="cash__form cash__form--npl">
                                <form class="form form--cash" v-on:submit.prevent="createPayment">
                                    <div class="form__main-columns">
                                        <div class="form__main">
                                            <div class="form__wrap">
                                                <label class="form__label _relative">
                                                    <span>{{ $store.state.translation.cashier.withdraw_type.payment_amount }}</span>
                                                    <span
                                                        :class="{'_form-error': errors.amount}"
                                                        class="form__input-wrap _before">
                                                        <input
                                                            v-model="amount"
                                                            @input="errors.amount = false"
                                                            autocomplete="off"
                                                            type="text"
                                                            :placeholder="parseFloat(paysystem.min_payment) * 2"
                                                            class="input"/>
                                                    </span>
                                                    <span class="error-input" v-if="errors.amount">{{ errors.amount }}</span>
                                                </label>

                                                <label class="form__label">
                                                    <span>{{ wallet_mask.text }}</span>
                                                    <span 
                                                        :class="{'_form-error': errors.wallet}"
                                                        class="form__input-wrap _before">
                                                        <input
                                                            v-model="wallet"
                                                            @input="errors.wallet = false, inputCard()"
                                                            @change="validateWallet"
                                                            @paste.preven="['visa', 'mastercard', 'maestro', 'mir'].includes(paysystem.slug)"
                                                            autocomplete="off"
                                                            type="text"
                                                            v-mask="wallet_mask.mask"
                                                            :placeholder="wallet_mask.placeholder"
                                                            class="input"/>
                                                    </span>
                                                    <span class="error-input" v-if="errors.wallet">{{ errors.wallet }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="personal-bonus-btn" type="submit" :class="{'disabled-button': loader_button}">
                                        <loading-button v-if="loader_button"/>
                                        {{ $store.state.translation.cashier.withdraw_type.withdraw_btn }}
                                    </button>
                                </form>
                                <div class="cash__notification" v-if="message">
                                    {{ message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    //const WAValidator = require('multicoin-address-validator')

    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.cashier.withdraw_type.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.cashier.withdraw_type.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.cashier.withdraw_type.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.cashier.withdraw_type.meta.description
                    }
                ]
            }
        },
        props: ['paysys_id', 'slug'],
        data() {
            return {
                loader_button: false,
                message: null,
                paysystem: null,
                amount: null,
                wallet: null,
                errors: {
                    amount: false,
                    wallet: false
                },
                wallet_mask: {
                    mask: null,
                    placeholder: null,
                    text: null
                },
                tippy: {
                    trigger: 'manual'
                }
            }
        },
        methods: {
            createPayment () {
                if(!this.amount) {
                    this.errors.amount = this.$store.state.translation.default.field_required
                    return
                }

                if(this.amount < parseFloat(this.paysystem.min_payment)) {
                    this.errors.amount = this.$sprintf(this.$store.state.translation.cashier.withdraw_type.min_payment, this.paysystem.min_payment, this.$store.state.user.data.merchant.currency)
                    return
                }

                if(!this.wallet) {
                    this.errors.wallet = this.$store.state.translation.default.field_required
                    return
                }

                if(this.errors.wallet) {
                    return
                }

                this.loader_button = true

                this.$axios.post('/cashier/withdrawal/create', JSON.stringify({
                    paysystem: {
                        id: this.paysystem.id,
                        slug: this.paysystem.slug
                    },
                    amount: this.amount,
                    wallet: this.wallet
                }))
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.message = response.message
                            this.wallet = null
                            this.amount = null

                            this.errors.wallet = false
                            this.errors.amount = false

                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)

                        if('field' in response) {
                            this.errors[response.field] = response.message2 ? response.message2 : response.message
                        }
                    }
                })
            },
            getPaysystem () {
                this.$axios.post('/cashier/paysystems/item', JSON.stringify({
                    type: 'withdrawal',
                    id: this.$props.paysys_id,
                    slug: this.$props.slug
                }))
                .then((response) => {
                    this.setLoaded(true)
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.paysystem = response.data

                            this.maskWallet()

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }

                    if(error.response.status == 404) {
                        this.$router.push({name: 'CashierWithdraw'})
                    }
                })
            },
            maskWallet() {
                if(['visa', 'mastercard', 'maestro', 'mir'].includes(this.paysystem.slug)) {
                    this.wallet_mask = {
                        mask: '####-####-####-####',
                        placeholder: '0000 0000 0000 0000',
                        text: this.$store.state.translation.cashier.withdraw_type.card_number
                    }
                } else if(this.paysystem.slug == 'qiwi') {
                    this.wallet_mask = {
                        mask: '+7##########',
                        placeholder: '+70000000000',
                        text: this.$store.state.translation.cashier.withdraw_type.wallet_number
                    }
                } else if(this.paysystem.slug == 'yoomoney') {
                    this.wallet_mask = {
                        //mask: '4##############',
                        placeholder: '400000000000000',
                        text: this.$store.state.translation.cashier.withdraw_type.wallet_number
                    }
                } else if(this.paysystem.slug == 'trc20') {
                    this.wallet_mask = {
                        mask: 'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN',
                        placeholder: '1KFzzGtDdnq5hrwxXGjwVnKzRbvf8WVxck',
                        text: this.$store.state.translation.cashier.withdraw_type.trc20_number
                    }
                }
            },
            validateWallet() {
                if(['visa', 'mastercard', 'maestro', 'mir'].includes(this.paysystem.slug)) {
                    if(!this.validateCard(this.wallet)) {
                        this.errors.wallet = this.$store.state.translation.cashier.withdraw_type.invalid_card
                    } else {
                        this.errors.wallet = false
                    }
                } /*else if(this.paysystem.slug == 'trc20') {
                    if(WAValidator.validate(this.wallet, 'litecoin')) {
                        this.errors.wallet = 'Недействительный кошельк TRC20'
                    } else {
                        this.errors.wallet = false
                    }
                }*/
            },
            inputCard() {
                if(['visa', 'mastercard', 'maestro', 'mir'].includes(this.paysystem.slug)) {
                    if(this.wallet.length >= 20) {
                        this.wallet_mask = {
                            mask: '####-####-####-####-##',
                            placeholder: '0000 0000 0000 0000 00',
                            text: this.$store.state.translation.cashier.withdraw_type.card_number
                        }
                    } else {
                        this.wallet_mask = {
                            mask: '####-####-####-####',
                            placeholder: '0000 0000 0000 0000',
                            text: this.$store.state.translation.cashier.withdraw_type.card_number
                        }
                    }
                }
            },
            validateCard(number) {
                return true
                
                number = number.replace(/\s/g, '')
                number = number.replaceAll('-', '')

                var regex = new RegExp("^[0-9]{16}$")
                if (!regex.test(number)) {
                    return false;
                }

                var sum = 0

                for (var i = 0; i < number.length; i++) {
                    var intVal = parseInt(number.substr(i, 1))
                    if (i % 2 == 0) {
                        intVal *= 2
                        if (intVal > 9) {
                            intVal = 1 + (intVal % 10)
                        }
                    }
                    sum += intVal
                }

                return (sum % 10) == 0
            },
            typpyHide (e) {
                this.tippy.trigger = 'mouseenter focus'
            }
        },
        beforeMount() {
            this.setLoaded(false)
            this.getPaysystem()
        }
    }
</script>