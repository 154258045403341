<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="video_poker"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.video_poker.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.video_poker.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#rules" data-goto-header>{{ $store.state.translation.video_poker.rules }}</button></li>
                                    <li><button type="button" data-goto="#features" data-goto-header>{{ $store.state.translation.video_poker.features }}</button></li>
                                    <li><button type="button" data-goto="#free" data-goto-header>{{ $store.state.translation.video_poker.free }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.video_poker.game }}</button></li>
                                    <li><button type="button" data-goto="#questions" data-goto-header>{{ $store.state.translation.video_poker.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.video_poker.text1 }}
                            </p>
                            <div class="providers">
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <section id="rules" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.video_poker.text2 }}</h2>
                            <p>
                                {{ $store.state.translation.video_poker.text3 }}
                            </p>
                            <ul class="list">
                                <li>
                                    {{ $store.state.translation.video_poker.text4 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.video_poker.text5 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.video_poker.text6 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.video_poker.text7 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.video_poker.text8 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.video_poker.text9 }}
                            </p>
                            <ul class="list">
                                <li>
                                    {{ $store.state.translation.video_poker.text10 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.video_poker.text11 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.video_poker.text12 }}
                            </p>
                        </div>
                    </section>
                    <section id="features" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.video_poker.text13 }}</h2>
                            <p>
                                {{ $store.state.translation.video_poker.text14 }}
                            </p>
                            <p>
                                {{ $store.state.translation.video_poker.text15 }}
                            </p>
                        </div>
                    </section>
                    <section id="free" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.video_poker.text16 }}</h2>
                            <p>
                                {{ $store.state.translation.video_poker.text17 }}
                            </p>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.video_poker.text18 }}</h2>
                            <p>
                                {{ $store.state.translation.video_poker.text19 }}
                            </p>
                            <p>
                                {{ $store.state.translation.video_poker.text20 }}
                            </p>
                        </div>
                    </section>
                    <section id="questions" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.video_poker.text21 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.video_poker.text22 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.video_poker.text23 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.video_poker.text24 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.video_poker.text25 }}</p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.video_poker.text26 }}</h3>
                                    <p>{{ $store.state.translation.video_poker.text27 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.video_poker.text28 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.video_poker.text29 }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.video_poker.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.video_poker.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.video_poker.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.video_poker.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
