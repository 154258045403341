<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="table_games"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.table_games.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.table_games.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#features" data-goto-header>{{ $store.state.translation.table_games.features }}</button></li>
                                    <li><button type="button" data-goto="#benefits" data-goto-header>{{ $store.state.translation.table_games.benefits }}</button></li>
                                    <li><button type="button" data-goto="#online" data-goto-header>{{ $store.state.translation.table_games.online }}</button></li>
                                    <li><button type="button" data-goto="#blackjack" data-goto-header>{{ $store.state.translation.table_games.blackjack }}</button></li>
                                    <li><button type="button" data-goto="#roulette" data-goto-header>{{ $store.state.translation.table_games.roulette }}</button></li>
                                    <li><button type="button" data-goto="#craps" data-goto-header>{{ $store.state.translation.table_games.craps }}</button></li>
                                    <li><button type="button" data-goto="#baccarat" data-goto-header>{{ $store.state.translation.table_games.baccarat }}</button></li>
                                    <li><button type="button" data-goto="#free" data-goto-header>{{ $store.state.translation.table_games.free }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.table_games.game }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.table_games.text1 }}
                            </p>
                            <div class="providers">
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/1x2gaming.png" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/belatra.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/evolution.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/wazdan.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/habanero.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/irondog.svg" width="40" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/mascotgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/yggdrasilgaming.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/redtiger.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/spinomenal.png" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/gameart.png" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/netgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/realdealer.png" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/redrake.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/relaxgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/swintt.svg" width="40" height="27" alt="">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <section id="features" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text2 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text3 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.table_games.text4 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text5 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text6 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text7 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text8 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="benefits" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text9 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text10 }}
                            </p>
                        </div>
                    </section>
                    <section id="online" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text11 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text12 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.table_games.text13 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text14 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text15 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text16 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text17 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="blackjack" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text18 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text19 }}
                            </p>
                            <p>
                                {{ $store.state.translation.table_games.text20 }}
                            </p>
                            <div class="providers providers--four">
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/evolution.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/mascotgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/habanero.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/redtiger.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="roulette" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text21 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text22 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.table_games.text23 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text24 }}
                                </li>
                                <li>{{ $store.state.translation.table_games.text25 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.table_games.text26 }}
                            </p>
                        </div>
                    </section>
                    <section id="craps" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text27 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text28 }}
                            </p>
                        </div>
                    </section>
                    <section id="baccarat" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text29 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text30 }}
                            </p>
                            <div class="providers providers--four">
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/evolution.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/mascotgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/habanero.svg" width="100" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                                <div class="providers-img">
                                    <picture>
                                        <img src="../assets/img/providers/redtiger.svg" width="80" height="27" alt="">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="free" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text31 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text32 }}
                            </p>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.table_games.text33 }}</h2>
                            <p>
                                {{ $store.state.translation.table_games.text34 }}
                            </p>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.table_games.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.table_games.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.table_games.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.table_games.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
