<template>
	<div class="wrapper">
        
        <header-main></header-main>

        <main class="page page--pt">

            <section class="content content--mb content--mb-stat">
                <div class="content__container">

                    <account-personal-info></account-personal-info>


                    <div class="levels">
                        <div class="levels__main-title">
                            <h2 class="form-title">{{ $store.state.translation.account.experience.balance }}</h2>
                        </div>
                        <div class="levels__line">
                            <router-link class="levels__link levels__link--left" :to="{name: 'Experience', params: {id: 'first'}, query: {scroll: 'top'}}">
                                <span>{{ $store.state.translation.account.experience.top_players }}</span>
                            </router-link>
                            <div class="levels__title-block">
                                <div class="levels__title">{{ experience.level.number }} {{ $store.state.translation.account.experience.level }}</div>
                            </div>
                            <div class="levels__line-block">
                                <div class="levels__experience">{{ experience.exp.toFixed(4) }}% exp</div>
                                <router-link class="levels__link levels__link--right" :to="{name: 'ExperienceShop'}">
                                    <span>{{ $store.state.translation.account.experience.exchange }}</span>
                                </router-link>
                            </div>
                        </div>
                        <div class="levels__list">

                            <div class="levels__level level level--first" v-for="level in experience.template" :class="{'_active': level == experience.level.number || level < experience.level.number}">
                                <div class="level__progress">
                                    <span v-if="level < experience.level.number">
                                        100%
                                    </span>
                                    <span v-else-if="level == experience.level.number">
                                        {{ experience.level.percent }}%
                                    </span>
                                    <span v-else-if="level > experience.level.number">
                                        0%
                                    </span>
                                    /100.00%
                                </div>
                                <div class="level__line">
                                    <div class="level__line-main">
                                        <div class="level__num"><span>{{ level }} </span></div>
                                        <div class="level__text">{{ $store.state.translation.account.experience.level }}</div>
                                    </div>
                                    <div class="level__line-progress">
                                        <svg id="line-progress-desktop" width="995" height="19" viewBox="0 0 995 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <template v-if="level < experience.level.number">
                                                <path 
                                                    v-for="(item) in path.desktop"
                                                    :d="item" 
                                                    stroke="#FF0"/>
                                            </template>
                                            <template v-else-if="level == experience.level.number">
                                                <path 
                                                    v-for="(item, index) in path.desktop" 
                                                    :d="item" 
                                                    :stroke="index + 1 <= experience.level.percent ? '#FF0' : '#909090'"/>
                                            </template>
                                            <template v-else-if="level > experience.level.number">
                                                <path
                                                    v-for="(item) in path.desktop"
                                                    :d="item" 
                                                    stroke="#909090"/>
                                            </template>
                                        </svg>
                                        <svg id="line-progress-mobile" width="297" height="10" viewBox="0 0 297 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <template v-if="level < experience.level.number">
                                                <path 
                                                    v-for="(item) in path.mobile"
                                                    :d="item" 
                                                    stroke="#FF0"/>
                                            </template>
                                            <template v-else-if="level == experience.level.number">
                                                <path 
                                                    v-for="(item, index) in path.mobile" 
                                                    :d="item" 
                                                    :stroke="index + 1 <= experience.level.percent_mobile ? '#FF0' : '#909090'"/>
                                            </template>
                                            <template v-else-if="level > experience.level.number">
                                                <path
                                                    v-for="(item) in path.mobile"
                                                    :d="item" 
                                                    stroke="#909090"/>
                                            </template>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </main>
        
        <footer-main></footer-main>
    </div>
</template>

<script>
export default {
    metaInfo () {
        return {
            title: this.$store.state.translation.account.experience.meta.title,
            meta: [
                {
                    name: 'title', 
                    content: this.$store.state.translation.account.experience.meta.title
                },
                {
                    name: 'keywords', 
                    content: this.$store.state.translation.account.experience.meta.keywords
                },
                {
                    name: 'description', 
                    content: this.$store.state.translation.account.experience.meta.description
                }
            ]
        }
    },
    data () {
        return {
            experience: {
            	exp: 0,
            	level: {
            		number: 0,
            		percent: 0,
                    percent_mobile: 0
            	},
            	template: []
            },
            path: {
                desktop: [
                    'M0.5 0.521315V18.3117L4.5 18.4787V0.688349L0.5 0.521315Z',
                    'M10.5 0.521315V18.3117L14.5 18.4787V0.688349L10.5 0.521315Z',
                    'M20.5 0.521315V18.3117L24.5 18.4787V0.688349L20.5 0.521315Z',
                    'M30.5 0.521315V18.3117L34.5 18.4787V0.688349L30.5 0.521315Z',
                    'M40.5 0.521315V18.3117L44.5 18.4787V0.688349L40.5 0.521315Z',
                    'M50.5 0.521315V18.3117L54.5 18.4787V0.688349L50.5 0.521315Z',
                    'M60.5 0.521315V18.3117L64.5 18.4787V0.688349L60.5 0.521315Z',
                    'M70.5 0.521315V18.3117L74.5 18.4787V0.688349L70.5 0.521315Z',
                    'M80.5 0.521315V18.3117L84.5 18.4787V0.688349L80.5 0.521315Z',
                    'M90.5 0.521315V18.3117L94.5 18.4787V0.688349L90.5 0.521315Z',
                    'M100.5 0.521315V18.3117L104.5 18.4787V0.688349L100.5 0.521315Z',
                    'M110.5 0.521315V18.3117L114.5 18.4787V0.688349L110.5 0.521315Z',
                    'M120.5 0.521315V18.3117L124.5 18.4787V0.688349L120.5 0.521315Z',
                    'M130.5 0.521315V18.3117L134.5 18.4787V0.688349L130.5 0.521315Z',
                    'M140.5 0.521315V18.3117L144.5 18.4787V0.688349L140.5 0.521315Z',
                    'M150.5 0.521315V18.3117L154.5 18.4787V0.688349L150.5 0.521315Z',
                    'M160.5 0.521315V18.3117L164.5 18.4787V0.688349L160.5 0.521315Z',
                    'M170.5 0.521315V18.3117L174.5 18.4787V0.688349L170.5 0.521315Z',
                    'M180.5 0.521315V18.3117L184.5 18.4787V0.688349L180.5 0.521315Z',
                    'M190.5 0.521315V18.3117L194.5 18.4787V0.688349L190.5 0.521315Z',
                    'M200.5 0.521315V18.3117L204.5 18.4787V0.688349L200.5 0.521315Z',
                    'M210.5 0.521315V18.3117L214.5 18.4787V0.688349L210.5 0.521315Z',
                    'M220.5 0.521315V18.3117L224.5 18.4787V0.688349L220.5 0.521315Z',
                    'M230.5 0.521315V18.3117L234.5 18.4787V0.688349L230.5 0.521315Z',
                    'M240.5 0.521315V18.3117L244.5 18.4787V0.688349L240.5 0.521315Z',
                    'M250.5 0.521315V18.3117L254.5 18.4787V0.688349L250.5 0.521315Z',
                    'M260.5 0.521315V18.3117L264.5 18.4787V0.688349L260.5 0.521315Z',
                    'M270.5 0.521315V18.3117L274.5 18.4787V0.688349L270.5 0.521315Z',
                    'M280.5 0.521315V18.3117L284.5 18.4787V0.688349L280.5 0.521315Z',
                    'M290.5 0.521315V18.3117L294.5 18.4787V0.688349L290.5 0.521315Z',
                    'M300.5 0.521315V18.3117L304.5 18.4787V0.688349L300.5 0.521315Z',
                    'M310.5 0.521315V18.3117L314.5 18.4787V0.688349L310.5 0.521315Z',
                    'M320.5 0.521315V18.3117L324.5 18.4787V0.688349L320.5 0.521315Z',
                    'M330.5 0.521315V18.3117L334.5 18.4787V0.688349L330.5 0.521315Z',
                    'M340.5 0.521315V18.3117L344.5 18.4787V0.688349L340.5 0.521315Z',
                    'M350.5 0.521315V18.3117L354.5 18.4787V0.688349L350.5 0.521315Z',
                    'M360.5 0.521315V18.3117L364.5 18.4787V0.688349L360.5 0.521315Z',
                    'M370.5 0.521315V18.3117L374.5 18.4787V0.688349L370.5 0.521315Z',
                    'M380.5 0.521315V18.3117L384.5 18.4787V0.688349L380.5 0.521315Z',
                    'M390.5 0.521315V18.3117L394.5 18.4787V0.688349L390.5 0.521315Z',
                    'M400.5 0.521315V18.3117L404.5 18.4787V0.688349L400.5 0.521315Z',
                    'M410.5 0.521315V18.3117L414.5 18.4787V0.688349L410.5 0.521315Z',
                    'M420.5 0.521315V18.3117L424.5 18.4787V0.688349L420.5 0.521315Z',
                    'M430.5 0.521315V18.3117L434.5 18.4787V0.688349L430.5 0.521315Z',
                    'M440.5 0.521315V18.3117L444.5 18.4787V0.688349L440.5 0.521315Z',
                    'M450.5 0.521315V18.3117L454.5 18.4787V0.688349L450.5 0.521315Z',
                    'M460.5 0.521315V18.3117L464.5 18.4787V0.688349L460.5 0.521315Z',
                    'M470.5 0.521315V18.3117L474.5 18.4787V0.688349L470.5 0.521315Z',
                    'M480.5 0.521315V18.3117L484.5 18.4787V0.688349L480.5 0.521315Z',
                    'M490.5 0.521315V18.3117L494.5 18.4787V0.688349L490.5 0.521315Z',
                    'M500.5 0.521315V18.3117L504.5 18.4787V0.688349L500.5 0.521315Z',
                    'M510.5 0.521315V18.3117L514.5 18.4787V0.688349L510.5 0.521315Z',
                    'M520.5 0.521315V18.3117L524.5 18.4787V0.688349L520.5 0.521315Z',
                    'M530.5 0.521315V18.3117L534.5 18.4787V0.688349L530.5 0.521315Z',
                    'M540.5 0.521315V18.3117L544.5 18.4787V0.688349L540.5 0.521315Z',
                    'M550.5 0.521315V18.3117L554.5 18.4787V0.688349L550.5 0.521315Z',
                    'M560.5 0.521315V18.3117L564.5 18.4787V0.688349L560.5 0.521315Z',
                    'M570.5 0.521315V18.3117L574.5 18.4787V0.688349L570.5 0.521315Z',
                    'M580.5 0.521315V18.3117L584.5 18.4787V0.688349L580.5 0.521315Z',
                    'M590.5 0.521315V18.3117L594.5 18.4787V0.688349L590.5 0.521315Z',
                    'M600.5 0.521315V18.3117L604.5 18.4787V0.688349L600.5 0.521315Z',
                    'M610.5 0.521315V18.3117L614.5 18.4787V0.688349L610.5 0.521315Z',
                    'M620.5 0.521315V18.3117L624.5 18.4787V0.688349L620.5 0.521315Z',
                    'M630.5 0.521315V18.3117L634.5 18.4787V0.688349L630.5 0.521315Z',
                    'M640.5 0.521315V18.3117L644.5 18.4787V0.688349L640.5 0.521315Z',
                    'M650.5 0.521315V18.3117L654.5 18.4787V0.688349L650.5 0.521315Z',
                    'M660.5 0.521315V18.3117L664.5 18.4787V0.688349L660.5 0.521315Z',
                    'M670.5 0.521315V18.3117L674.5 18.4787V0.688349L670.5 0.521315Z',
                    'M680.5 0.521315V18.3117L684.5 18.4787V0.688349L680.5 0.521315Z',
                    'M690.5 0.521315V18.3117L694.5 18.4787V0.688349L690.5 0.521315Z',
                    'M700.5 0.521315V18.3117L704.5 18.4787V0.688349L700.5 0.521315Z',
                    'M710.5 0.521315V18.3117L714.5 18.4787V0.688349L710.5 0.521315Z',
                    'M720.5 0.521315V18.3117L724.5 18.4787V0.688349L720.5 0.521315Z',
                    'M730.5 0.521315V18.3117L734.5 18.4787V0.688349L730.5 0.521315Z',
                    'M740.5 0.521315V18.3117L744.5 18.4787V0.688349L740.5 0.521315Z',
                    'M750.5 0.521315V18.3117L754.5 18.4787V0.688349L750.5 0.521315Z',
                    'M760.5 0.521315V18.3117L764.5 18.4787V0.688349L760.5 0.521315Z',
                    'M770.5 0.521315V18.3117L774.5 18.4787V0.688349L770.5 0.521315Z',
                    'M780.5 0.521315V18.3117L784.5 18.4787V0.688349L780.5 0.521315Z',
                    'M790.5 0.521315V18.3117L794.5 18.4787V0.688349L790.5 0.521315Z',
                    'M800.5 0.521315V18.3117L804.5 18.4787V0.688349L800.5 0.521315Z',
                    'M810.5 0.521315V18.3117L814.5 18.4787V0.688349L810.5 0.521315Z',
                    'M820.5 0.521315V18.3117L824.5 18.4787V0.688349L820.5 0.521315Z',
                    'M830.5 0.521315V18.3117L834.5 18.4787V0.688349L830.5 0.521315Z',
                    'M840.5 0.521315V18.3117L844.5 18.4787V0.688349L840.5 0.521315Z',
                    'M850.5 0.521315V18.3117L854.5 18.4787V0.688349L850.5 0.521315Z',
                    'M860.5 0.521315V18.3117L864.5 18.4787V0.688349L860.5 0.521315Z',
                    'M870.5 0.521315V18.3117L874.5 18.4787V0.688349L870.5 0.521315Z',
                    'M880.5 0.521315V18.3117L884.5 18.4787V0.688349L880.5 0.521315Z',
                    'M890.5 0.521315V18.3117L894.5 18.4787V0.688349L890.5 0.521315Z',
                    'M900.5 0.521315V18.3117L904.5 18.4787V0.688349L900.5 0.521315Z',
                    'M910.5 0.521315V18.3117L914.5 18.4787V0.688349L910.5 0.521315Z',
                    'M920.5 0.521315V18.3117L924.5 18.4787V0.688349L920.5 0.521315Z',
                    'M930.5 0.521315V18.3117L934.5 18.4787V0.688349L930.5 0.521315Z',
                    'M940.5 0.521315V18.3117L944.5 18.4787V0.688349L940.5 0.521315Z',
                    'M950.5 0.521315V18.3117L954.5 18.4787V0.688349L950.5 0.521315Z',
                    'M960.5 0.521315V18.3117L964.5 18.4787V0.688349L960.5 0.521315Z',
                    'M970.5 0.521315V18.3117L974.5 18.4787V0.688349L970.5 0.521315Z',
                    'M980.5 0.521315V18.3117L984.5 18.4787V0.688349L980.5 0.521315Z',
                    'M990.5 0.521315V18.3117L994.5 18.4787V0.688349L990.5 0.521315Z'
                ],
                mobile: [
                    'M0.5 0.518651V9.40809L2.5 9.48135V0.59191L0.5 0.518651Z',
                    'M6.5 0.518651V9.40809L8.5 9.48135V0.59191L6.5 0.518651Z',
                    'M12.5 0.518651V9.40809L14.5 9.48135V0.59191L12.5 0.518651Z',
                    'M18.5 0.518651V9.40809L20.5 9.48135V0.59191L18.5 0.518651Z',
                    'M24.5 0.518651V9.40809L26.5 9.48135V0.59191L24.5 0.518651Z',
                    'M30.5 0.518651V9.40809L32.5 9.48135V0.59191L30.5 0.518651Z',
                    'M36.5 0.518651V9.40809L38.5 9.48135V0.59191L36.5 0.518651Z',
                    'M42.5 0.518651V9.40809L44.5 9.48135V0.59191L42.5 0.518651Z',
                    'M48.5 0.518651V9.40809L50.5 9.48135V0.59191L48.5 0.518651Z',
                    'M54.5 0.518651V9.40809L56.5 9.48135V0.59191L54.5 0.518651Z',
                    'M60.5 0.518651V9.40809L62.5 9.48135V0.59191L60.5 0.518651Z',
                    'M66.5 0.518651V9.40809L68.5 9.48135V0.59191L66.5 0.518651Z',
                    'M72.5 0.518651V9.40809L74.5 9.48135V0.59191L72.5 0.518651Z',
                    'M78.5 0.518651V9.40809L80.5 9.48135V0.59191L78.5 0.518651Z',
                    'M84.5 0.518651V9.40809L86.5 9.48135V0.59191L84.5 0.518651Z',
                    'M90.5 0.518651V9.40809L92.5 9.48135V0.59191L90.5 0.518651Z',
                    'M96.5 0.518651V9.40809L98.5 9.48135V0.59191L96.5 0.518651Z',
                    'M102.5 0.518651V9.40809L104.5 9.48135V0.59191L102.5 0.518651Z',
                    'M108.5 0.518651V9.40809L110.5 9.48135V0.59191L108.5 0.518651Z',
                    'M114.5 0.518651V9.40809L116.5 9.48135V0.59191L114.5 0.518651Z',
                    'M120.5 0.518651V9.40809L122.5 9.48135V0.59191L120.5 0.518651Z',
                    'M126.5 0.518651V9.40809L128.5 9.48135V0.59191L126.5 0.518651Z',
                    'M132.5 0.518651V9.40809L134.5 9.48135V0.59191L132.5 0.518651Z',
                    'M138.5 0.518651V9.40809L140.5 9.48135V0.59191L138.5 0.518651Z',
                    'M144.5 0.518651V9.40809L146.5 9.48135V0.59191L144.5 0.518651Z',
                    'M150.5 0.518651V9.40809L152.5 9.48135V0.59191L150.5 0.518651Z',
                    'M156.5 0.518651V9.40809L158.5 9.48135V0.59191L156.5 0.518651Z',
                    'M162.5 0.518651V9.40809L164.5 9.48135V0.59191L162.5 0.518651Z',
                    'M168.5 0.518651V9.40809L170.5 9.48135V0.59191L168.5 0.518651Z',
                    'M174.5 0.518651V9.40809L176.5 9.48135V0.59191L174.5 0.518651Z',
                    'M180.5 0.518651V9.40809L182.5 9.48135V0.59191L180.5 0.518651Z',
                    'M186.5 0.518651V9.40809L188.5 9.48135V0.59191L186.5 0.518651Z',
                    'M192.5 0.518651V9.40809L194.5 9.48135V0.59191L192.5 0.518651Z',
                    'M198.5 0.518651V9.40809L200.5 9.48135V0.59191L198.5 0.518651Z',
                    'M204.5 0.518651V9.40809L206.5 9.48135V0.59191L204.5 0.518651Z',
                    'M210.5 0.518651V9.40809L212.5 9.48135V0.59191L210.5 0.518651Z',
                    'M216.5 0.518651V9.40809L218.5 9.48135V0.59191L216.5 0.518651Z',
                    'M222.5 0.518651V9.40809L224.5 9.48135V0.59191L222.5 0.518651Z',
                    'M228.5 0.518651V9.40809L230.5 9.48135V0.59191L228.5 0.518651Z',
                    'M234.5 0.518651V9.40809L236.5 9.48135V0.59191L234.5 0.518651Z',
                    'M240.5 0.518651V9.40809L242.5 9.48135V0.59191L240.5 0.518651Z',
                    'M246.5 0.518651V9.40809L248.5 9.48135V0.59191L246.5 0.518651Z',
                    'M252.5 0.518651V9.40809L254.5 9.48135V0.59191L252.5 0.518651Z',
                    'M258.5 0.518651V9.40809L260.5 9.48135V0.59191L258.5 0.518651Z',
                    'M264.5 0.518651V9.40809L266.5 9.48135V0.59191L264.5 0.518651Z',
                    'M270.5 0.518651V9.40809L272.5 9.48135V0.59191L270.5 0.518651Z',
                    'M276.5 0.518651V9.40809L278.5 9.48135V0.59191L276.5 0.518651Z',
                    'M282.5 0.518651V9.40809L284.5 9.48135V0.59191L282.5 0.518651Z',
                    'M288.5 0.518651V9.40809L290.5 9.48135V0.59191L288.5 0.518651Z',
                    'M294.5 0.518651V9.40809L296.5 9.48135V0.59191L294.5 0.518651Z',
                ]
            }
        }
    },
    methods: {
        getExperience () {            
            this.$axios.post('/users/experience')
            .then((response) => {
                this.setLoaded(true)
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        this.experience.exp = parseFloat(response.data)

                        this.experience.level = {
                        	number: Math.ceil(this.experience.exp / 100),
                        	percent: (this.experience.exp / 100) == 20 ? 100 : Math.ceil(this.experience.exp % 100),
                            percent_mobile: (this.experience.exp / 100) == 20 ? 50 : Math.ceil((this.experience.exp / 2) % 100)
                        }

                        if(this.experience.level.number <= 3) {
                    		this.experience.template = [1, 2, 3, 15, 20]
                    	} else if(this.experience.level.number > 3 && this.experience.level.number < 15) {
                    		this.experience.template = [1, this.experience.level.number - 1, this.experience.level.number, 15, 20]
                    	} else if(this.experience.level.number >= 15 && this.experience.level.number <= 17) {
                    		this.experience.template = [1, this.experience.level.number - 1, this.experience.level.number, this.experience.level.number + 1, 20]
                    	} else if(this.experience.level.number > 17) {
                    		this.experience.template = [1, 17, 18, 19, 20]
                    	}

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.default.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        }
    },
    beforeMount() {
    	this.setLoaded(false)
    	this.getExperience()
    }
}
</script>