<template>
    <div class="wrapper">
        
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb content--mb-very-big">
                <div class="content__container payment-history-row">

                    <cashier-personal-info></cashier-personal-info>
                    
                    <div class="content__filters content__filters--two content__filters__payments">
                        <div class="content__select-item">
                            <span>{{ $store.state.translation.cashier.payment_history.status }}:</span>
                            <div class="content__select _before filters_search_select">
                                <div class="select_ select_cashier select_form _select-active">
                                    <v-select 
                                        :options="utils.statuses"
                                        v-model="data.status"
                                        @input="payments = [], data.offset = 0, getPayments()"
                                        placeholder="Статус транзакции"
                                        class="filter"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                           {{ $store.state.translation.default.select_not_found }}
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="content__select-item">
                            <span>{{ $store.state.translation.cashier.payment_history.type }}:</span>
                            <div class="content__select _before filters_search_select">
                                <div class="select_ select_cashier select_form _select-active">
                                    <v-select 
                                        :options="utils.types"
                                        v-model="data.type"
                                        @input="payments = [], data.offset = 0, getPayments()"
                                        :placeholder="$store.state.translation.cashier.payment_history.type"
                                        class="filter"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                            {{ $store.state.translation.default.select_not_found }}
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table class="content__table table-pc table-pc--six">
                        <thead>
                            <tr>
                                <th>{{ $store.state.translation.cashier.payment_history.id }}</th>
                                <th>{{ $store.state.translation.cashier.payment_history.date }}</th>
                                <th>{{ $store.state.translation.cashier.payment_history.amount }}</th>
                                <th>{{ $store.state.translation.cashier.payment_history.type }}</th>
                                <th>{{ $store.state.translation.cashier.payment_history.paysys }}</th>
                                <th>{{ $store.state.translation.cashier.payment_history.status }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in payments">
                                <td>{{ row.uuid }}</td>
                                <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                <td>{{ parseFloat(row.amount).toFixed(2) }}</td>
                                <td>{{ utils.types.find(el => el.key == row.type).name }}</td>
                                <td>
                                    {{ capitalizeFirstLetter(row.method) }}
                                    <template v-if="row.type == 'withdrawal'">
                                       / {{ row.wallet.substring(0,10) }}<template v-if="row.wallet.length > 10">...</template>
                                    </template>
                                </td>
                                <td>
                                    <span :class="{'_orange': row.status == 0, '_green': row.status == 1, '_red': row.status == 2, '_red': row.status == 3, '_orange': row.status == 4}">
                                        {{ utils.statuses.find(el => el.key == row.status).name }}
                                    </span>

                                    <!--
                                    <img
                                        v-if="row.status == 0 && row.type == 'withdrawal'"
                                        @click="cancelWithdrawal(row.id)"
                                        :class="{'disabled-button': cancel_loader}"
                                        :content="$store.state.translation.cashier.payment_history.cancel_withdraw"
                                        v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                        src="../../assets/img/cancel.svg" 
                                        class="withdrawal_cancel">
                                    -->

                                    <img
                                        v-if="row.status == 2"
                                        :content="row.reject_message"
                                        v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                        src="../../assets/img/question-circle.svg" 
                                        class="verification_question_circle">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="history-preloader" v-if="preloader">
                        <div class="preloader__row">
                            <div class="preloader__item"></div>
                            <div class="preloader__item"></div>
                        </div>
                    </div>

                    <div class="show-more__container content--mt">
                        <div class="show-more__row show-more__row--center">
                            <button type="button" class="show-more__more" v-if="!nomore" @click="getPayments" :class="{'_disabled': preloader}">
                                <span>{{ $store.state.translation.default.show_more }}</span>
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.cashier.payment_history.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.cashier.payment_history.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.cashier.payment_history.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.cashier.payment_history.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                nomore: true,
                preloader: false,
                cancel_loader: false,

                payments: [],
                data: {
                    status: null,
                    type: null,
                    limit: 10,
                    offset: 0
                },
                
                utils: {
                    statuses: [
                        {
                            name: this.$store.state.translation.cashier.payment_history.done,
                            key: 1
                        },
                        {
                            name: this.$store.state.translation.cashier.payment_history.waiting,
                            key: 0
                        },
                        {
                            name: this.$store.state.translation.cashier.payment_history.processing,
                            key: 4
                        },
                        {
                            name: this.$store.state.translation.cashier.payment_history.rejected,
                            key: 2
                        },
                        {
                            name: this.$store.state.translation.cashier.payment_history.canceled,
                            key: 3
                        }
                    ],
                    types: [
                        {
                            name: this.$store.state.translation.cashier.payment_history.deposit,
                            key: 'deposit'
                        },
                        {
                            name: this.$store.state.translation.cashier.payment_history.withdraw,
                            key: 'withdrawal'
                        }
                    ]
                }
            }
        },
        methods: {
            getPayments () {
                this.preloader = true

                this.$axios.post('/cashier/payments/history', JSON.stringify({
                    status: this.data.status ? this.data.status.key : null,
                    type: this.data.type ? this.data.type.key : null,
                    limit: this.data.limit,
                    offset: this.data.offset
                }))
                .then((response) => {
                    this.preloader = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.payments = this.payments.concat(response.payments)
                            this.data.offset ++
                            if(response.payments.length < this.data.limit) {
                                this.nomore = true
                            } else {
                                this.nomore = false
                            }

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            /*cancelWithdrawal (id) {
                this.cancel_loader = true

                this.$axios.post('/cashier/withdrawal/cancel', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    this.cancel_loader = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            let index = this.payments.findIndex(el => el.id == id)
                            this.payments[index].status = 3

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    this.cancel_loader = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },*/
            capitalizeFirstLetter(str) {
              return str[0].toUpperCase() + str.slice(1)
            }
        },
        beforeMount() {
            this.getPayments()
        }
    }
</script>
