<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="slots"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.slots.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.slots.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#providers" data-goto-header>{{ $store.state.translation.slots.providers }}</button></li>
                                    <li><button type="button" data-goto="#slots" data-goto-header>{{ $store.state.translation.slots.slots }}</button></li>
                                    <li><button type="button" data-goto="#advantages" data-goto-header>{{ $store.state.translation.slots.title }}</button></li>
                                    <li><button type="button" data-goto="#mobile" data-goto-header>{{ $store.state.translation.slots.mobile }}</button></li>
                                    <li><button type="button" data-goto="#free" data-goto-header>{{ $store.state.translation.slots.free }}</button></li>
                                    <li><button type="button" data-goto="#game" data-goto-header>{{ $store.state.translation.slots.game }}</button></li>
                                    <li><button type="button" data-goto="#responsible" data-goto-header>{{ $store.state.translation.slots.responsible }}</button></li>
                                    <li><button type="button" data-goto="#questions" data-goto-header>{{ $store.state.translation.slots.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>{{ $store.state.translation.slots.text1 }}</p>
                            <h3 class="content__title mini-title">{{ $store.state.translation.slots.text2 }}</h3>
                            <p>{{ $store.state.translation.slots.text3 }}</p>
                        </div>
                    </div>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <div id="providers" class="content">
                        <div class="content__container">
                            <table class="content__table table table--two">
                                <caption>{{ $store.state.translation.slots.text4 }}</caption>
                                <thead>
                                    <tr>
                                        <th>{{ $store.state.translation.slots.text5 }}</th>
                                        <th>{{ $store.state.translation.slots.text6 }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/1x2gaming.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text7 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/2by2-gaming.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text8 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/ainsworthgametechnology.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text9 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/amatic.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text10 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/belatra.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text11 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/betsoft.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text12 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/bigtimegaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text13 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/boominggames.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text14 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/booongo.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text15 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/bfgames.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text16 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/blueprint.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text17 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/endorphina.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text18 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/elk.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text19 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/genesis.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text20 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/ganapati.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text21 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/habanero.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text22 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/irondog.svg" width="40" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text23 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/justforthewin.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text24 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/mascotgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text25 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/microgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text26 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/netent.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text27 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/nolimitcity.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text28 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/pushgaming.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text29 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/playson.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text30 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/pragmaticplay.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text31 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/hacksawgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text32 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/playngo.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text33 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/quickspin.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text34 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/rabcat.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text35 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/redtiger.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text36 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/reelplay.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text37 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/relaxgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text38 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/spinomenal.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text39 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/thunderkick.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text40 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/tomhorn.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text41 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/wazdan.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text42 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/yggdrasilgaming.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text43 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/gameart.png" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text44 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/liveg24.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text45 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/redrake.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text46 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/revolvergaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text47 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/eyecon.svg" width="100" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text48 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/netgaming.svg" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text49 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/pgsoft.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text50 }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="providers-img">
                                            <picture>
                                                <img src="../assets/img/providers/realdealer.png" width="80" height="27" alt="">
                                            </picture>
                                        </td>
                                        <td>
                                            {{ $store.state.translation.slots.text51 }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <section id="slots" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text52 }}</h2>
                            <p>
                                {{ $store.state.translation.slots.text53 }}
                            </p>
                            <ul class="list">
                                <li>{{ $store.state.translation.slots.text54 }}</li>
                                <li>{{ $store.state.translation.slots.text55 }}</li>
                                <li>{{ $store.state.translation.slots.text56 }}</li>
                                <li>{{ $store.state.translation.slots.text57 }}</li>
                                <li>{{ $store.state.translation.slots.text58 }}</li>
                                <li>{{ $store.state.translation.slots.text59 }}</li>
                                <li>{{ $store.state.translation.slots.text60 }}</li>
                            </ul>
                        </div>
                    </section>
                    <section id="advantages" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text61 }}</h2>
                            <p>
                                {{ $store.state.translation.slots.text62 }}
                            </p>
                            <p>
                                {{ $store.state.translation.slots.text63 }}
                            </p>
                            <p>
                                {{ $store.state.translation.slots.text64 }}
                            </p>
                        </div>
                    </section>
                    <section id="mobile" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text65 }}</h2>
                            <p>
                                {{ $store.state.translation.slots.text66 }}
                            </p>
                            <ul class="list">
                                <li>
                                    {{ $store.state.translation.slots.text67 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text68 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text69 }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.slots.text70 }}
                            </p>
                        </div>
                    </section>
                    <section id="free" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text71 }}</h2>
                            <p>
                                {{ $store.state.translation.slots.text72 }}
                            </p>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text73 }}</h2>
                            <p>
                                {{ $store.state.translation.slots.text74 }}
                            </p>
                            <ul class="list">
                                <li>
                                    {{ $store.state.translation.slots.text75 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text76 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text77 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text78 }}
                                </li>
                                <li>
                                    {{ $store.state.translation.slots.text79 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="responsible" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text80 }}</h2>
                            <p>{{ $store.state.translation.slots.text81 }}
                            </p>
                            <p>{{ $store.state.translation.slots.text82 }}
                            </p>
                        </div>
                    </section>
                    <section id="questions" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.slots.text83 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.slots.text84 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.slots.text85 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.slots.text86 }}</h3>
                                    <p class="content__paragraph">{{ $store.state.translation.slots.text87 }}
                                    </p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.slots.text88 }}</h3>
                                    <p>{{ $store.state.translation.slots.text89 }}</p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.slots.text90 }}</h3>
                                    <p class="content__paragraph">
                                        {{ $store.state.translation.slots.text91 }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.slots.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.slots.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.slots.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.slots.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
