import Vue from 'vue'
import Vuex from 'vuex'
import Localization from '../Langs'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loaded: false,
    loaded_games: false,
    lang: null,
    languages: {},
    translation: {},
    user: {
      data: {
        merchant: {
          currency: 'USD',
          currency_sign: '$'
        },
        games_favourite: []
      }
    },
    promo_statuses: [],
    promo_templates: [],
    banners: [],
    categories: [],
    providers: []
  },
  mutations: {
    setLoaded(state, loaded) {
      state.loaded = loaded
    },
    setLoadedGames(state, loaded) {
      state.loaded_games = loaded
    },
    setLang(state, value) {
      state.lang = value
    },
    setLanguages(state, value) {
      state.languages = value
    },
    setTranslation(state, value) {
      state.translation = value
    },
    setUser(state, data) {
      if(data && 'auth' in data) {
        if('data' in data) {
            data.data.balance = parseFloat(data.data.balance)
            data.data.balance_bonus = parseFloat(data.data.balance_bonus)
        }
        state.user = data
      } else {
        state.user = {}
      }
    },
    setPromoStatuses(state, data) {
      state.promo_statuses = data
    },
    setPromoTemplates(state, data) {
      state.promo_templates = data
    }
  }
})