<template>
    <div class="wrapper">
        
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb">
                <div class="content__container">

                    <account-personal-info></account-personal-info>

                </div>
            </section>
            <section class="content">
                <h2 v-if="$store.state.user.data.confirmations.kyc" class="verified-account">{{ $store.state.translation.account.verification.already_verified }}</h2>

                <div class="content__container content__container__verification">
                    <h2 class="content__middle-title content__middle-title--mb">{{ $store.state.translation.account.verification.text1 }}</h2>

                    <ol class="content__verification-list n-list">
                        <li>{{ $store.state.translation.account.verification.text2 }}</li>
                        <li>{{ $store.state.translation.account.verification.text3 }}</li>
                    </ol>

                    <div class="content__verification-document _decor-line _decor-line--full">
                        <h2 class="content__middle-title content__middle-title--min">{{ $store.state.translation.account.verification.text4 }}</h2>
                        <ul class="list list--nmb">
                            <li>
                                {{ $store.state.translation.account.verification.text5 }} <br />
                                {{ $store.state.translation.account.verification.text6 }} <br />
                                {{ $store.state.translation.account.verification.text7 }}
                            </li>
                            <li>{{ $store.state.translation.account.verification.text8 }}</li>
                            <li>{{ $store.state.translation.account.verification.text9 }}</li>
                            <li>{{ $store.state.translation.account.verification.text10 }}</li>
                        </ul>
                        <span>
                            <span class="_white">{{ $store.state.translation.account.verification.text11 }}</span>
                            {{ $store.state.translation.account.verification.text12 }}
                        </span>
                    </div>

                    <h2 class="content__middle-title content__middle-title--min">{{ $store.state.translation.account.verification.text13 }}</h2>

                    <div class="content__verification-lists verification-lists _decor-line _decor-line--full">
                        <div class="verification-lists__main">
                            <h4 class="verification-lists__title">{{ $store.state.translation.account.verification.text14 }}</h4>
                            <ul class="list _decor-line _decor-line--full">
                                <li>{{ $store.state.translation.account.verification.text15 }}</li>
                                <li>{{ $store.state.translation.account.verification.text16 }}</li>
                            </ul>
                            <h4 class="verification-lists__title">{{ $store.state.translation.account.verification.text17 }}</h4>
                            <ul class="list _decor-line _decor-line--full">
                                <li>{{ $store.state.translation.account.verification.text18 }}</li>
                                <li>{{ $store.state.translation.account.verification.text19 }}</li>
                                <li>{{ $store.state.translation.account.verification.text20 }}</li>
                            </ul>
                            <h4 class="verification-lists__title">{{ $store.state.translation.account.verification.text21 }}</h4>
                            <ul class="list _decor-line _decor-line--full">
                                <li>{{ $store.state.translation.account.verification.text22 }}</li>
                                <li>{{ $store.state.translation.account.verification.text23 }}</li>
                            </ul>
                            <h4 class="verification-lists__title">{{ $store.state.translation.account.verification.text24 }}</h4>
                            <ul class="list _decor-line _decor-line--full">
                                <li>{{ $store.state.translation.account.verification.text25 }}</li>
                                <li>{{ $store.state.translation.account.verification.text26 }}</li>
                            </ul>
                        </div>
                        <span>
                            <span class="_yellow">{{ $store.state.translation.account.verification.text27 }}</span>
                            {{ $store.state.translation.account.verification.text28 }}
                        </span>
                    </div>

                    <div class="verification-id">
                        <div class="verification-id__header" v-if="files.length">
                            <h2 class="form-title">{{ $store.state.translation.account.verification.text29 }}</h2>
                        </div>

                        <table class="content__table verification-table" v-if="files.length">
                            <thead>
                                <tr>
                                    <th>{{ $store.state.translation.account.verification.text30 }}</th>
                                    <th>{{ $store.state.translation.account.verification.text31 }}</th>
                                    <th>{{ $store.state.translation.account.verification.text32 }}</th>
                                    <th>{{ $store.state.translation.account.verification.text33 }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in files">
                                    <td>{{ row.id }}</td>
                                    <td>
                                        <a 
                                            :content="`<img src='${$config.ApplicationAPI}/users/kyc/file/${row.id}?api_token=${api_token}'>`"
                                            v-tippy="{arrow : true, arrowType: 'round', placement: 'top', trigger: 'click', theme: 'verification_file', maxWidth: 500}"
                                            class="verification-file-image">
                                            {{ row.name }}
                                        </a>
                                    </td>
                                    <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                    <td>
                                        <span 
                                        :class="{'_orange': row.status == 0, '_green': row.status == 1, '_red': row.status == 2}">{{ utils.statuses.find(el => el.key == row.status).name }}</span>
                                        <img
                                            v-if="row.status == 2"
                                            :content="row.reject_message"
                                            v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                            src="../../assets/img/question-circle.svg" 
                                            class="verification_question_circle">
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="show-more__container">
                            <div class="show-more__row show-more__row--center">
                                <button type="button" class="show-more__more" v-if="!nomore" @click="getFiles" :class="{'_disabled': btn_preloader_more}">
                                    <span>{{ $store.state.translation.default.show_more }}</span>
                                </button>
                            </div>
                        </div>


                        <div class="verification-id__main verification-id-block" v-if="!success_upload && !$store.state.user.data.confirmations.kyc">
                            <div class="verification-id-block__title">{{ $store.state.translation.account.verification.text34 }}</div>
                            <div class="verification-id-block__text">{{ $store.state.translation.account.verification.text35 }}</div>
                            <div class="file">
                                <div class="file__item">
                                    <input ref="file" type="file" accept=".jpg, .jpeg, .png, .gif, .pdf" class="file__input" @input="uploadFile" style="display: none;" />
                                    <button
                                        @click.prevent="$refs.file.click()"
                                        :disabled="btn_preloader"
                                        :class="{'_disabled': btn_preloader}"
                                        type="button" 
                                        class="file__button verification-id-btn">
                                        <template v-if="data.file_name">
                                            <div class="lds-ring" v-if="btn_preloader"><div></div><div></div><div></div><div></div></div>

                                            {{ data.file_name }}
                                        </template>
                                        <template v-else>{{ $store.state.translation.account.verification.text36 }}</template>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="verification-id__main verification-id-block verification-id-block--notification" v-if="success_upload">
                            <div class="verification-id-block__title">{{ $store.state.translation.account.verification.text37 }}</div>
                            <div class="verification-id-block__text">
                                {{ $store.state.translation.account.verification.text38 }} <span class="_green">{{ $store.state.translation.account.verification.text39 }}</span>. <br />
                                {{ $store.state.translation.account.verification.text40 }}
                            </div>
                            <button type="button" class="verification-id-btn" @click="success_upload = false">{{ $store.state.translation.account.verification.text41 }}</button>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
export default {
    metaInfo () {
        return {
            title: this.$store.state.translation.account.verification.meta.title,
            meta: [
                {
                    name: 'title', 
                    content: this.$store.state.translation.account.verification.meta.title
                },
                {
                    name: 'keywords', 
                    content: this.$store.state.translation.account.verification.meta.keywords
                },
                {
                    name: 'description', 
                    content: this.$store.state.translation.account.verification.meta.description
                }
            ]
        }
    },
    data () {
        return {
            api_token: localStorage.getItem('token'),
            nomore: true,
            btn_preloader: false,
            btn_preloader_more: false,
            success_upload: false,
            files: [],
            data: {
                file_name: null
            },
            pager: {
                limit: 10,
                offset: 0
            },
            utils: {
                statuses: [
                    {
                        name: this.$store.state.translation.account.verification.confirmed,
                        key: 1
                    },
                    {
                        name: this.$store.state.translation.account.verification.waiting,
                        key: 0
                    },
                    {
                        name: this.$store.state.translation.account.verification.rejected,
                        key: 2
                    },
                ]
            }
        }
    },
    methods: {
        getFiles () {
            this.btn_preloader_more = true
            this.$axios.post('/users/kyc/items', JSON.stringify(this.pager))
            .then((response) => {
                this.btn_preloader_more = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        this.files = this.files.concat(response.data)
                        this.pager.offset ++
                        if(response.data.length < this.pager.limit) {
                            this.nomore = true
                        } else {
                            this.nomore = false
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.default.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        uploadFile (e) {
            var file = e.target.files[0]
            this.$refs.file.value = null

            if(file) {
                if (file.size > 10000000) {
                    this.$toast.error(this.$store.state.translation.account.verification.max_size)
                    return
                }

                this.data.file_name = file.name

                this.btn_preloader = true

                var formData = new FormData()
                formData.append('file', file)

                this.$axios.post('/users/kyc/upload', formData)
                .then((response) => {
                    this.data.file_name = null
                    this.btn_preloader = false
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            this.success_upload = true

                            this.files.unshift({
                                id: response.file_id,
                                name: file.name,
                                created_at: this.$moment().format('YYYY-MM-DD H:mm:ss'),
                                status: 0
                            })

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    this.data.file_name = null
                    this.btn_preloader = false
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        }
    },
    beforeMount() {
        this.getFiles()
    }
}
</script>
