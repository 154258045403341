<template>
    <div class="wrapper">

        <header-main></header-main>

        <main class="page page--pt-big">
            <div class="game">
                <div class="game__container">
                    <div class="game__main">
                        <div class="game_frame_box">
                            <div class="game-iframe__header">
                                <span>{{ game.name }}</span>
                                <div class="game-iframe__icon-wrapper">
                                    <svg 
                                        @click="fullscreen"
                                        :content="$store.state.translation.game.text1" 
                                        v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                        class="game-iframe__header-icon" 
                                        height="15px" width="15px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><title/><desc/><defs/><g fill-rule="evenodd" stroke="none" stroke-width="1"><g fill="#ffffff" id="Core" transform="translate(-215.000000, -257.000000)"><g id="fullscreen" transform="translate(215.000000, 257.000000)"><path d="M2,9 L0,9 L0,14 L5,14 L5,12 L2,12 L2,9 L2,9 Z M0,5 L2,5 L2,2 L5,2 L5,0 L0,0 L0,5 L0,5 Z M12,12 L9,12 L9,14 L14,14 L14,9 L12,9 L12,12 L12,12 Z M9,0 L9,2 L12,2 L12,5 L14,5 L14,0 L9,0 L9,0 Z" id="Shape"/></g></g></g>
                                    </svg>
                                    <svg 
                                        @click="refreshGame"
                                        :content="$store.state.translation.game.text2" 
                                        v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                        class="game-iframe__header-icon" 
                                        xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 60 60">
                                        <path fill="currentColor" fill-rule="evenodd" d="M47.815 32.619c0 9.56-7.46 16.454-16.632 16.454-9.17 0-16.63-7.502-16.63-16.6 0-9.097 7.46-16.424 16.63-16.424h.637v4.289l13.606-7.857L31.82 4.625v5.052h-.637C18.538 9.677 8.25 19.995 8.25 32.569c0 12.573 10.288 22.806 22.933 22.806 12.646 0 22.933-11.285 22.933-22.756h-6.3z"></path>
                                    </svg>
                                    <svg 
                                        @click="closeGame"
                                        :content="$store.state.translation.game.text3" 
                                        v-tippy="{arrow : true, arrowType: 'round', theme: 'rejected_doc'}"
                                        class="game-iframe__header-icon" 
                                        xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 60 60">
                                        <path fill="currentColor" fill-rule="evenodd" d="M54.7 5.3a3.5 3.5 0 0 0-5 0L30 25.1 10.2 5.3a3.5 3.5 0 1 0-4.9 5L25.1 30 5.3 49.8a3.5 3.5 0 0 0 5 4.9L30 34.9l19.8 19.8a3.5 3.5 0 1 0 4.9-5L34.9 30l19.8-19.8a3.5 3.5 0 0 0 0-4.9Z"></path>
                                    </svg>
                                </div>
                            </div>

                            <iframe ref="game_frame" class="game_frame" id="game_frame"></iframe>
                        </div>
                    </div>
                    <div class="game__sidebar" ref="game__sidebar">
                        <div class="game__sidebar-content">
                            <div class="game-tabs _tab-init">
                                <nav class="game-tabs__navigation">
                                    <button type="button" class="game-tabs__title _tab-active" @click="changeTab($event, '.tab_profile_info')">{{ $store.state.translation.game.text4 }}</button>
                                    <button type="button" class="game-tabs__title" @click="changeTab($event, '.tab_more_games'), getGames()">{{ $store.state.translation.game.text5 }}</button>
                                </nav>
                                <div class="game-tabs__content">

                                    <div class="game-tabs__body tab_profile_info" v-if="('auth' in $store.state.user) && $store.state.user.auth">

                                        <div class="box_menu_profile">
                                            <div class="username">
                                                {{$store.state.user.data.username}}
                                            </div>

                                            <div class="user_status">
                                                {{ $store.state.translation.game.text6 }} : {{$store.state.user.data.rating.name}}
                                            </div>

                                            <div class="progress-status-row">
                                                <div class="box_progress">
                                                    <span>{{$store.state.user.data.rating.name}}</span>
                                                    <span>{{nextstatus.status}}</span>
                                                </div>
                                                <div class="status_progress" style="height: 8px;">
                                                    <div class="status_progress-bar" :style="`width: ${nextstatus.percent}%;`"></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="box_menu_buttons">
                                            <div class="game__button promo-link promo-link--static deposit-button">
                                                <router-link :to="{name: 'CashierDeposit'}" target="_blank">
                                                    {{ $store.state.translation.game.text7 }}
                                                </router-link>
                                            </div>

                                            <div class="game__button promo-link promo-link--static help-button bottom-zero">
                                                <a @click="openHelp">
                                                    {{ $store.state.translation.game.text8 }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="game-tabs__body tab_profile_info" v-else>
                                        <div class="box_menu_profile">
                                            <div class="username">
                                                {{ $store.state.translation.game.text9 }}
                                            </div>

                                            <div class="aster_registration">
                                                {{ $store.state.translation.game.text10 }}
                                            </div>
                                        </div>

                                        <div class="box_menu_buttons">
                                            <div class="game__button promo-link registration-button">
                                                <router-link :to="{name: 'Registration'}">
                                                    {{ $store.state.translation.game.text11 }}
                                                </router-link>
                                            </div>

                                            <div class="game__button promo-link help-button">
                                                <a @click="openHelp">
                                                    {{ $store.state.translation.game.text12 }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="game-tabs__body game-tabs__items tab_more_games hidden">
                                        <div class="game-tabs__item game-cards__item game-card" v-for="game in games">
                                            <div class="game-card__main">
                                                <div class="game-card__img">
                                                    <picture>
                                                        <img :src="game.image" width="141" height="96" :alt="game.name" />
                                                    </picture>
                                                </div>
                                                <div class="game-card__hover game-slider-hover">
                                                    <template v-if="'auth' in $store.state.user && $store.state.user.auth">
                                                        <router-link :to="{name: 'Game', params: {mode: 'real', slug: game.slug}}" class="game-slider-hover__play">
                                                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -7.31543e-07 14.7358 -6.64245e-07 13.1962L-2.09983e-07 2.80385C-1.42685e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z"
                                                                    fill="white"
                                                                ></path>
                                                            </svg>
                                                        </router-link>
                                                    </template>
                                                    <template v-else>
                                                        <button type="button" class="game-slider-hover__play" @click="openPopup('sign-in')">
                                                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M12 6.26795C13.3333 7.03775 13.3333 8.96225 12 9.73205L3 14.9282C1.66667 15.698 -7.31543e-07 14.7358 -6.64245e-07 13.1962L-2.09983e-07 2.80385C-1.42685e-07 1.26425 1.66667 0.301995 3 1.0718L12 6.26795Z"
                                                                    fill="white"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                    </template>
                                                    <router-link :to="{name: 'Game', params: {mode: 'demo', slug: game.slug}}" class="game-slider-hover__link">{{ $store.state.translation.game.text13 }}</router-link>
                                                </div>
                                            </div>
                                            <div class="game-card__footer">
                                                <div class="game-card__title">{{game.name}}</div> 
                                                <div class="game-card__title game-card__title--hover">{{game.name}}</div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="game__mobile" v-if="1==2">
                        <div class="game-tabs _tab-init">
                            <div class="game-tabs__content">

                                <div class="game-tabs__body">
                                    <div class="box_menu_profile">
                                        <div class="name">
                                            {{game.name}}
                                        </div>

                                        <div class="picture">
                                            <img :src="game.image" width="141" height="96" :alt="game.name" >
                                        </div>
                                    </div>

                                    <div class="box_menu_buttons">
                                        <div class="game__button promo-link">
                                            <a @click="playMobile">
                                                {{ $store.state.translation.game.text14 }}
                                            </a>
                                        </div>

                                        <div class="game__button promo-link back-button">
                                            <router-link v-if="$props.mode == 'real' && 'auth' in $store.state.user && $store.state.user.auth" :to="{name: 'Game', params:{mode: 'demo'}}">
                                                {{ $store.state.translation.game.text15 }}
                                            </router-link>
                                            <router-link v-else :to="{name: 'Game', params:{mode: 'real'}}">
                                                {{ $store.state.translation.game.text16 }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>


<script>
    export default {
        metaInfo () {
            return {
                title: this.$sprintf(this.$store.state.translation.game.meta.title, (this.game.name || '')),
                meta: [
                    {
                        name: 'title', 
                        content: this.$sprintf(this.$store.state.translation.game.meta.title, (this.game.name || '')),
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.game.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$sprintf(this.$store.state.translation.game.meta.description, (this.game.name || ''))
                    }
                ]
            }
        },
        props: ['mode', 'slug'],
        data() {
            return {
                nextstatus: {
                    status: null,
                    percent: 0,
                    checked: false
                },
                games: [],
                game: {
                    id: null,
                    name: null,
                    image: null
                }
            }
        },
        methods: {
            getGameReal () {
                this.setLoaded(false)
                this.$axios.post(this.$config.ApplicationGamesAPI + '/init', JSON.stringify({
                    slug: this.$props.slug,
                    return_url: 'https://' + window.location.hostname,
                    language: 'ru'
                }), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    withCredentials: false
                })
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            this.$router.push({name: 'Home'})
                            break
                        case 'success':

                            this.game = response.game

                            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                window.location = response.url
                            } else {
                                this.$refs.game_frame.setAttribute('src', response.url)
                                this.setLoaded(true)
                            }
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                            this.$router.push({name: 'Home'})
                    }
                }).catch((error) => {
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        this.$router.push({name: 'Home'})
                    }
                })
            },
            getGameDemo () {
                this.setLoaded(false)
                this.$axios.create().post(this.$config.ApplicationGamesAPI + '/init-demo', JSON.stringify({
                    slug: this.$props.slug,
                    return_url: 'https://' + window.location.hostname,
                    language: 'ru'
                }), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                })
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            this.$router.push({name: 'Home'})
                            break
                        case 'success':

                            this.game = response.game

                            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                                window.location = response.url
                            } else {
                                this.$refs.game_frame.setAttribute('src', response.url)
                                this.setLoaded(true)
                            }

                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                            this.$router.push({name: 'Home'})
                    }
                }).catch((error) => {
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                        this.$router.push({name: 'Home'})
                    }
                })
            },
            getNextstatus () {
                this.$axios.post('/users/status/next')
                .then((response) => {
                      response = response.data
                      switch (response.status) {
                          case 'error':
                              break
                          case 'success':

                              this.nextstatus = response.data
                              this.nextstatus.checked = true

                              this.setUser(response.user)

                              break
                          default:
                          //Error
                      }
                })
            },
            getGames () {
                if(this.games.length) {
                    return
                }
                this.$axios.post('/games/search', JSON.stringify({
                    type: 'search',
                    query: null,
                    provider: null,
                    category: null,
                    filters: {
                        is_favourite: false,
                        in_tournaments: false, 
                        bonus_buy:false, 
                        is_megaways:false
                    },
                    not_games: [this.game.id],
                    limit: 6,
                    offset: 0
                }))
                .then((response) => {
                    this.setLoaded(true)
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            break
                        case 'success':

                            this.games = response.data

                            this.setUser(response.user)
                            
                            break
                        default:
                    }
                })
            },
            changeTab (e, tab) {
                var elements = document.querySelectorAll('.game-tabs__title')

                for (let el of elements) {
                    el.classList.remove('_tab-active')
                }
                
                e.target.classList.add('_tab-active')

                var body_elemets = document.querySelectorAll('.game-tabs__body')

                for (let el of body_elemets) {
                    el.classList.add('hidden')
                }

                document.querySelector(tab).classList.remove('hidden')
            },
            fullscreen () {
                var frame = document.querySelector('#game_frame')

                if (frame.requestFullscreen) {
                    frame.requestFullscreen()
                } else if (frame.webkitRequestFullscreen) {
                    frame.webkitRequestFullscreen()
                } else if (frame.msRequestFullscreen) {
                    frame.msRequestFullscreen()
                }
            },
            refreshGame () {
                window.location = ''
            },
            closeGame () {
                this.$router.push({name: 'Home'})
            },
            openHelp () {
                window.chaport.on('ready', function () {
                  window.chaport.open()
                })
            }
        },
        beforeMount() {

            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                window.addEventListener('popstate', function(event) {
                    window.location = '/'
                }, false)

                var perfEntries = performance.getEntriesByType('navigation')
                if (perfEntries.length && perfEntries[0].type === 'back_forward') {
                    window.location = '/'
                }
            }

            if(this.$props.mode == 'real' && 'auth' in this.$store.state.user && this.$store.state.user.auth) {
                this.getGameReal()
            } else {
                this.getGameDemo()
            }

            if(('auth' in this.$store.state.user) && this.$store.state.user.auth) {
                this.getNextstatus()
            }
        },
        watch: {
            '$store.state.user' (user, old) {
                if(('auth' in this.$store.state.user) && this.$store.state.user.auth && !this.nextstatus.checked) {
                    this.getNextstatus()
                }
            },
            '$props.slug' (user, old) {
                this.$refs.game_frame.setAttribute('src', null)

                if(this.$props.mode == 'real' && 'auth' in this.$store.state.user && this.$store.state.user.auth) {
                    this.getGameReal()
                } else {
                    this.getGameDemo()
                }
            }
        }
    }
</script>
