import Vue from "vue"

import Auth from "./components/common/Auth"
import HeaderMain from "./components/common/Header-Main"
import FooterMain from "./components/common/Footer-Main"
import AccountPersonalInfo from "./components/common/Account-Personal-Info"
import CashierPersonalInfo from "./components/common/Cashier-Personal-Info"
import LoadingButton from "./components/common/Loading-Button"
import HomeBanner from "./components/common/Home-Banner"
import HomeNavigationFilters from "./components/common/Home-Navigation-Filters"
import HomeGameSlider from "./components/common/Home-Game-Slider"
import HomeGameCards from "./components/common/Home-Game-Cards"

Vue.component('auth', Auth)
Vue.component('header-main', HeaderMain)
Vue.component('footer-main', FooterMain)
Vue.component('account-personal-info', AccountPersonalInfo)
Vue.component('cashier-personal-info', CashierPersonalInfo)
Vue.component('loading-button', LoadingButton)
Vue.component('home-banner', HomeBanner)
Vue.component('home-navigation-filters', HomeNavigationFilters)
Vue.component('home-game-slider', HomeGameSlider)
Vue.component('home-game-cards', HomeGameCards)